<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />

    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{$t("editTagItem.editTag")}}</h3>
            </div>
            <!-- <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to list</base-button
              >
            </div> -->
          </div>
          <div class="card-body">

            <form ref="profile_form" @submit.prevent="handleSubmit">
              <base-input
                :label="$t('editTagItem.name')+'*'"
                v-model="tag.name"
                name="Name"
                required
              />
              <validation-error :errors="apiValidationErrors.name" />

              <base-input :label="$t('editTagItem.color')">
                <color-picker v-model="color" style="width: 100%" />
              </base-input>
              <validation-error :errors="apiValidationErrors.color" />

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  <i class="fa fa-save"/> {{$t("editTagItem.updateTag")}}
                </base-button>
              </div>

            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Slider } from "vue-color";
import BackButton from "@/components/BackButton.vue";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
    "color-picker": Slider,
    BackButton,
  },

  data() {
    return {
      tag: {
        type: "tags",
        name: null,
        color: null,
      },
      color: {
        hex: "#FF0000",
      },
    };
  },
  created() {
    this.get();
  },

  watch: {
    color: {
      handler: "updateTagColor",
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        let params = {
          filter: {
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
          },
        };
        await this.$store
          .dispatch("tags/get", { id, params })
          .then(() => (this.tag = this.$store.getters["tags/tag"]));
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async handleSubmit() {
      if (this.$isDemo == 1 && ["1", "2"].includes(this.$route.params.id)) {
        this.$notify({
          type: "danger",
          message: this.$t('editTagItem.youAreNotAllowedNotChangeDataOfDefaultTags')+'.',
        });
        return;
      }
      try {
        await this.$store.dispatch("tags/update", this.tag);

        this.$notify({
          type: "success",
          message: this.$t('editTagItem.tagUpdatedSuccessfully')+'.',
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    updateTagColor() {
      this.tag.color = this.color.hex;
    },

    goBack() {
      this.$router.push({ name: "List Tags" });
    },
  },
};
</script>
