<template>
  <div class="row align-content-end">
    <div class="col">
<!--      <label :class="switchClass"></label>-->
      <badge v-if="notice" rounded :type="notice.badge" class="ml-1 mt--1">
        {{ notice.content }}
      </badge>

      <base-stats-card v-else-if="infobox" :title="infobox.label"
                       type="gradient-base"
                       :sub-title="infobox.content + '  ' + getCurrencyCode">

        <template slot="footer">
<!--          <span class="text-nowrap">Your total fees for this ticket are</span>-->
<!--          <span class="text-success mr-2"> {{ calcOrganizersFinalFees() }} {{ getCurrencyCode}} </span> <br>-->
          <span class="text-nowrap">The Attendees will pay</span>
          <span class="text-success "> {{ calcAttendeesFinalPayment() }} {{ getCurrencyCode}} </span>
          <span class="text-nowrap">per ticket incl. Service Fees </span>

        </template>
      </base-stats-card>

<!--      <base-alert v-else-if="infobox" type="base" class="mt&#45;&#45;1">-->
<!--        <span> {{ infobox.label }}:</span>-->
<!--        <span class="pl-3"> <strong>{{ getCurrencyCode }} {{ infobox.content }} </strong> </span>-->
<!--      </base-alert>-->


      <span v-else-if="description" class="text-sm text-muted mb-0 pt--4">
        {{ description.content }}
      </span>
    </div>
    <div class="col-auto mr--3">
      <label class="custom-toggle">
        <input type="checkbox" v-model="model" :disabled="disabled">
        <span class="custom-toggle-slider rounded-circle"
              :data-label-off="offText"
              :data-label-on="onText">
          </span>
      </label>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'base-switch',
    props: {
      value: [Array, Boolean, String],
      type: String,
      onText: {
        type: String,
        default:'Yes'
      },
      offText: {
        type: String,
        default: 'No'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      notice: {
        type: Object,
        default: () => {},
        required: false,
        content: '',
        badge: 'base'
      },
      description: {
        type: Object,
        default: () => {},
        required: false,
        content: '',
      },
      infobox :{
        type: Object,
        default: () => {},
        required: false,
        label: '',
        content: ''
      }
    },
    computed: {
      switchClass() {
        let baseClass = 'custom-toggle-';
        if (this.type) {
          return baseClass + this.type
        }
        return ''
      },
      getCurrencyCode() {
        return this.getCurrencySymbol();
      },
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },
    methods: {
      calcOrganizersFinalFees() {
        return ((this.infobox.content * this.getTicketingFinalPercentage().percentageFee / 100) + this.getTicketingFinalPercentage().fixedFee);
      },
      calcAttendeesFinalPayment() {
        const contentValue = parseFloat(this.infobox.content) || 0;
        return ((contentValue * (1 / 100)) + contentValue).toFixed(2);
      },
      triggerToggle() {
        this.model = !this.model;
      }
    }
  };
</script>
<style></style>
