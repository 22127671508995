<template>
  <div class="select-language-dropdown">
    <el-select
      v-model="$i18n.locale"
      style="max-width: 4rem"
      @change="updateHeader()"
    >
      <el-option
        class="mt-1"
        v-for="lang in langs"
        :key="lang"
        :value="lang"
        :label="getLanguageLabel(lang)"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";

export default {
  name: "back-button",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      langs: ["en", "el"],
    };
  },
  methods: {
    getLanguageLabel(lang) {
      return lang.toUpperCase();
    },
    updateHeader() {
      this.$http.defaults.headers.common["Custom-Language-Header"] =
        this.$i18n.locale;

      localStorage.setItem("selected-laguage", this.$i18n.locale);
    },
  },
};
</script>
<style></style>
