<template>
  <div class="container-fluid mt-4" :style="{ backgroundColor: '#ffffff' }">
    <!-- Navigation -->
    <base-public-nav v-if="!hideHeaderFooterLayout"
      id="event-main-navigation"
      container-classes="container-fluid col-md-11"
      class="navbar fixed-top navbar-horizontal navbar-expand-lg navbar-dark"
      type="gradient-base"
      v-model="showMenu"
    >
      <a slot="brand" class="navbar-brand text-right" href="#">
        <img :src="logomark" />
      </a>

      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <router-link to="/">
              <img :src="logomark" />
            </router-link>
          </div>

          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              @click="showMenu = false"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav ml-lg-auto">
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="#event-details">
            <i class="far fa-comment-alt"></i>
            {{ $t("publicLayout.details") }}
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="#event-gallery-media"
            id="navbar-default_dropdown_1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-image"></i>
            {{ $t("publicLayout.gallery") }}
          </a>
        </li>
        <li>
          <a
            class="nav-link nav-link-icon"
            href="#event-happening"
            id="navbar-default_dropdown_1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-map"></i>
            {{ $t("publicLayout.location") }}
          </a>
        </li>

        <li class="nav-item dropdown mt-2">
          <!-- ToDo - Develop Mobile Navigation-->
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbar-default_dropdown_1"
          >
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li>
        <li class="nav-item">
          <!-- i18n - Language Switcher -->
          <base-select-language class="mt-1 second-header"
          ></base-select-language>
        </li>
      </ul>
    </base-public-nav>

    <!-- Main event content -->
    <div class="container-fluid">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <!-- Footer-->
    <template v-if="!hideHeaderFooterLayout">
      <hr />
      <div class="row text-center">

        <div class="col-md-2 mb-3">
          <div class="copyright">
            <a href="http://www.bookmeinscotty.com">
              <img
                  :src="logomarkFooter"
                  style="max-width: 25%; max-height: 2rem"
                  class="mr-1"
              />
            </a>
          </div>
        </div>

        <div class="col-md-4 offset-md-2 mb-3">
          <div class="copyright">
            <small class="text-muted">
              <a href="http://www.bookmeinscotty.com/add-item" target="__blank">
                Host events at <u>no-cost</u> <strong>forever</strong> with
              </a>
            </small>
            <div class="w-100 d-none d-md-block mt-2"></div>

            <a href="http://www.bookmeinscotty.com/add-item">
              <img
                  :src="logo"
                  style="max-width: 33%; max-height: 2rem"
                  class="mr-1"
              />
            </a>
          </div>
        </div>

        <div class="col-md-3 offset-md-1">
          <div class="copyright">
            <a href="https://search.ipaustralia.gov.au/trademarks/search/view/2064790?q=Book+Me+In+Scotty" target="_blank">
              <small class="text-muted">
                © {{ copyrightYear }} | {{ $t("publicLayout.allRightsReserved") }}</small
              >
            </a>
          </div>
        </div>
      </div>

      <hr class="mb-1" />
    </template>
  </div>
</template>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
import { BaseSelectLanguage } from "@/components";

export default {
  components: {
    ZoomCenterTransition,
    BaseSelectLanguage,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
    logomark: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_LOGO,
      description: "Scotty brand logo",
    },
    logomarkFooter: {
      type: String,
      default: '/img/brand/bmis-default-full-logomark.png',
      description: "Scotty brand logo",
    },
    logo: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_FULL_LOGO,
      description: "Scotty full logo",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
      copyrightYear: new Date().getFullYear(),
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
    hideHeaderFooterLayout() {
      return this.$route?.query?.btnOnly === "true";
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  beforeDestroy() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>

<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.container-fluid {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
