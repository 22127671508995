<template>
  <div id="bmis-tabloid-layout" class="container-fluid">
    <!-- Hero and Happening Details-->
    <div
      class="row justify-content-center"
      id="event-hero-details"
      style="
        background-color: rgba(120, 137, 232, 0.1);
        colormargin-top: 5px !important;
        padding-bottom: 30px !important;
        border-radius: 0.75rem !important;
      "
    >
      <div class="col-md-10 order-md-0 mt-4">
        <card
          v-if="
            item?.show_latest_announcement &&
            announcements?.importance_type === 'high' &&
            announcements?.message.length > 0
          "
          style="border-style: solid; border-color: #5e72e4; border-width: 2px"
        >
          <div class="row align-items-center">
            <div class="col-auto ml-2">
              <span class="h6 surtitle text-muted">
                <i class="ni ni-notification-70" />
                {{$t("general.announcement")}}
              </span>
            </div>
            <div class="col text-right">
              <small class="text-muted">
                <i class="fas fa-clock mr-1" />
                {{ displayAnnouncementTime(announcements.updated_at) }}
              </small>
            </div>
          </div>
          <div class="col-auto ml-2">
            <h3>{{ announcements.message }}</h3>
          </div>
        </card>
      </div>

      <div class="col-md-7 mt-1">
        <el-image
          :src="getHeroImage()"
          class="d-block shadow-1-strong rounded w-200"
          alt="1"
          @load="heroImageLoaded = true"
        >
          <div slot="placeholder" class="image-slot">
            <el-skeleton :rows="6" animated>
              <template slot="template">
                <div style="padding: 14px">
                  <el-skeleton-item
                    variant="image"
                    style="width: 100%; height: 200px"
                  /><br />
                </div>
              </template>
            </el-skeleton>
          </div>
        </el-image>
      </div>

      <div class="col-md-3 order-md-1 mt-1">
        <!-- Happening section-->
        <div class="card">
          <div class="card-body">
            <h3 class="inline col-md-10 text-base">
             <i class="fa fa-calendar mr-2"/> {{$t("tabloidEventLandingpage.happeningDetails")}}
            </h3>
            

            <hr class="mb-2 mt--2" />
            <div class="d-flex justify-content-between pt-1">
              <div>
                <span class="h6 surtitle text-muted">
                  <i class="fas fa-clock" /> {{$t("general.when")}}
                </span>
              </div>
              <div class="text-right" v-if="item.event_type !== 'hybrid'">
                <small class="text-muted">
                  <el-tooltip
                    class="item"
                    style="border-radius: 0"
                    effect="light"
                    :content="
                      item.event_type === 'physical'
                        ? originalTimezone
                        : localTimezone
                    "
                    placement="top"
                  >
                    <i class="fa fa-info-circle" />
                  </el-tooltip>
                </small>
              </div>
            </div>

            <template
              v-if="
                item.event_type === 'tba' ||
                item.event_type === 'virtual' ||
                item.event_type === 'physical'
              "
            >
              <h2 v-if="eventTotalDuration < 1">{{ eventStartDateTime }}</h2>
              <h2 v-else>
                {{ eventStartDateTime }} <small>{{$t("tabloidEventLandingpage.to")}}</small>
                {{ eventEndDateTime }}
              </h2>

              <p class="card-text mt-4" style="margin-top: -5px !important">
                <small>{{$t("general.startsAt")}} </small>
                <strong class="text-underline"> {{ localStartTime }} </strong>
                <small>{{$t("general.until")}}</small>
                <strong class="text-underline"> {{ localEndTime }}</strong>
                <small v-if="eventTotalDuration >= 1">
                  &nbsp;
                  <badge type="base" tag="a" size="md">
                    {{$t("tabloidEventLandingpage.duration")}} {{ eventTotalDuration }} {{$t("general.day")}}
                  </badge>
                </small>
              </p>
            </template>
             <template v-else-if="item.event_type === 'hybrid'">
              <template>
                <small class="text-muted text-base ml-2">
                  {{
                    isHybridSameTimezone
                      ?  $t('general.inPersonAndVirtual')
                      : $t('tabloidEventLandingpage.inPerson')
                  }}
                  {{$t("tabloidEventLandingPage.eventTime")}}
                  <el-tooltip
                    class="item"
                    style="border-radius: 0"
                    effect="light"
                    :content="originalTimezone"
                    placement="top"
                  >
                    <i class="fa fa-info-circle" />
                  </el-tooltip>
                </small>

                <h2 v-if="eventTotalDuration < 1">
                  {{ eventStartDateTime }}
                </h2>
                <h2 v-else>
                  {{ eventStartDateTime }} <small>{{$t("tabloidEventLandingpage.to")}}</small>
                  {{ eventEndDateTime }}
                </h2>

                <p class="card-text mt-4" style="margin-top: -5px !important">
                  <small>{{$t("general.startsAt")}}</small>
                  <strong class="text-underline"
                    >{{ eventStartTime }}
                  </strong>
                  <small>{{$t("general.until")}}</small>
                  <strong class="text-underline">{{ eventEndTime }}</strong>
                  <small v-if="eventTotalDuration >= 1">
                    (+{{ eventTotalDuration }} days)
                  </small>
                </p>
              </template>

              <template v-if="!isHybridSameTimezone">
                  <small class="text-muted text-base ml-2">
                    Virtual event time
                    <el-tooltip
                      class="item"
                      style="border-radius: 0"
                      effect="light"
                      :content="localTimezone"
                      placement="top"
                    >
                      <i class="fa fa-info-circle" />
                    </el-tooltip>
                  </small>
                  <h2 v-if="eventTotalDuration < 1">
                    {{ eventStartDateTime }}
                  </h2>
                  <h2 v-else>
                    {{ eventStartDateTime }} <small>{{$t("tabloidEventLandingpage.to")}}</small>
                    {{ eventEndDateTime }}
                  </h2>
                  <p
                    class="card-text mt-4"
                    style="margin-top: -5px !important"
                  >
                    <small>{{$t("general.startsAt")}} </small>
                    <strong class="text-underline"
                      >{{ localStartTime }}
                    </strong>
                    <small>{{$t("general.until")}}</small>
                    <strong class="text-underline">{{ localEndTime }}</strong>
                    <small v-if="eventTotalDuration >= 1">
                      (+{{ eventTotalDuration }} days)
                    </small>
                  </p>
              </template>
            </template>

            <div class="row">
              <div class="col">
                <span class="h6 surtitle text-muted"
                  ><i class="ni ni-pin-3"></i>
                  {{$t("general.where")}}
                </span>
                <div v-if="item.event_type === 'physical'">
                  <span class="d-block h3">{{ venue.address_name }}</span>
                </div>
                <div
                  v-else-if="
                    item.event_type === 'hybrid' 
                  "
                >
                  <span class="d-block h3">{{ venue.address_name }}</span>
                  <base-alert type="secondary" class="ml--2">
                    <p class="text-muted text-wrap mt--4 mb--2">
                      This event is
                      {{ item.event_type === "hybrid" ? "also" : "only" }}
                      available online
                      <i class="fa fa-satellite-dish" />
                    </p>
                  </base-alert>
                </div>
                <div v-else-if="item.event_type === 'virtual'">
                  <base-alert type="secondary" class="ml--2">
                    <p class="text-muted text-wrap mt--4 mb--2">
                      This event is
                      {{ item.event_type === "hybrid" ? "also" : "only" }}
                      available online
                      <i class="fa fa-satellite-dish" />
                    </p>
                  </base-alert>
                </div>
                <div v-else-if="item.event_type === 'tba'">
                  <h4>{{$t("general.toBeAnnouncedSoon")}}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Social share section-->
        <card type="frame">
          <h3
              class="text-left text-base"
              style="display: inline"
              v-if="tags"
          >
            <i class="fas fa-share mr-2" /> {{$t("tabloidEventLandingpage.shareWithAudience")}}

            <!-- Card body -->
            <div class="row align-content-around">
              <div class="col-md-12 mb-1">
                <el-tooltip
                    class="item"
                    effect="dark"
                   :content="$t('tabloidEventLandingpage.copyThisEventsPublicLink')"
                    placement="bottom"
                >
                  <base-button
                      round
                      @click="copyURL()"
                      class="btn-base btn-icon-only pb-1 pt-1 mt-2 mb-2"
                  >
                  <span class="btn-inner--icon"
                  ><i class="fa fa-link"
                  /></span>
                  </base-button>
                </el-tooltip>

                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('tabloidEventLandingpage.shareThisEventViaEmail')"
                    placement="bottom"
                >
                  <a
                      :href="
                    'mailto:' +
                    '' +
                    '?subject=' +
                    '[Event] ' +
                    item.name +
                    ' (by Book Me In Scotty)' +
                    '&body=' +
                    '%0D%0A' +
                    'Hey,' +
                    '%0D%0A' +
                    'I found this amazing event - visit here for more: ' +
                    eventPublicURL +
                    '%0D%0A' +
                    virtualLinkMessage +
                    'Short description:' +
                    '%0D%0A' +
                    eventShortDescription +
                    '%0D%0A' +
                    '%0D%0A' +
                    'Event Tags: ' +
                    getHashtagsForEmail()
                  "
                  >
                    <base-button round class="btn btn-icon-only bg-danger">
                    <span class="btn-inner--icon">
                      <i class="fa fa-envelope" aria-hidden="true" />
                    </span>
                    </base-button>
                  </a>
                </el-tooltip>

                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('tabloidEventLandingpage.saveThisEventInYourCalendar')"
                    placement="bottom"
                >
                  <base-button
                      round
                      @click="getCalendarFile()"
                      class="btn btn-icon-only bg-info ml-2"
                  >
                  <span class="btn-inner--icon"
                  ><i class="fa fa-calendar"
                  /></span>
                  </base-button>
                </el-tooltip>

                <share-network
                    network="twitter"
                    :url="eventPublicURL"
                    :title="item.name"
                    :description="item.description"
                    :quote="item.name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                >
                  <base-button
                      class="btn-icon-only rounded-circle bg-dark"
                  >
                        <span class="btn-inner--icon"
                        ><font-awesome-icon icon="fab fa-x-twitter"/>
                        </span>
                  </base-button>
                </share-network>

                <share-network
                    network="facebook"
                    :url="eventPublicURL"
                    :title="item.name"
                    :description="item.description"
                    image="https://www.uniconexed.org/wp-content/uploads/canstockphoto21211673-online-event-770x662.jpg"
                    :quote="item.name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                >
                  <base-button
                      type="facebook"
                      class="btn-icon-only rounded-circle"
                  >
                        <span class="btn-inner--icon"
                        ><i class="fab fa-facebook"></i
                        ></span>
                  </base-button>
                </share-network>

                <share-network
                    network="linkedin"
                    :url="eventPublicURL"
                    :title="item.name"
                    :description="item.description"
                    :quote="item.name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                >
                  <base-button
                      type="vimeo"
                      class="btn-icon-only rounded-circle"
                  >
                        <span class="btn-inner--icon"
                        ><i class="fab fa-linkedin"></i
                        ></span>
                  </base-button>
                </share-network>

                <share-network
                    network="whatsapp"
                    :url="eventPublicURL"
                    :title="item.name"
                    :description="item.description"
                    :quote="item.name"
                    class="mr-2"
                >
                  <base-button
                      type="whatsapp"
                      class="btn-icon-only rounded-circle bg-success"
                  >
                        <span class="btn-inner--icon"
                        ><i class="fab fa-whatsapp text-white"/>
                        </span>
                  </base-button>
                </share-network>

              </div>
            </div>
          </h3>
        </card>
      </div>
    </div>

    <!-- Sticky CTA Booking Buttons -->
    <div
      class="row justify-content-center"
      id="sticky-buttons-top"
      v-if="hasBookButton"
    >
      <div class="col-md-10 mb--3">
        <card id="cta" type="frame">
          <div class="row align-content-end mt--4 mb--3">
            <div class="col-md-4 mb-2">
              <h6 class="surtitle text-muted mt-3">
                <i class="fas fa-ticket-alt" />
                {{$t("tabloidEventLandingpage.bookYourTicketWith")}}
              </h6>
            </div>
            <div class="col-md-4 offset-md-4">
              <book-button-component
                :item="item"
                :modals="modals"
                @addTicket="addTicket($event)"
              ></book-button-component>
            </div>
          </div>
        </card>
      </div>
    </div>
    

    <!--Latest Announcement-->
    <div class="row justify-content-center" id="event-latest-high-announcement">
      <div class="col-md-10 mt-1">
        <card
          v-if="
            item?.show_latest_announcement &&
            announcements?.importance_type === 'medium' &&
            announcements?.message.length > 0
          "
          style="border-style: solid; border-color: #5e72e4; border-width: 2px"
        >
          <div class="row align-items-center">
            <div class="col-auto ml-2">
              <span class="h6 surtitle text-muted">
                <i class="ni ni-notification-70" />
                {{$t("general.announcement")}}
              </span>
            </div>
            <div class="col text-right">
              <small class="text-muted">
                <i class="fas fa-clock mr-1" />
                {{ displayAnnouncementTime(announcements.updated_at) }}
              </small>
            </div>
          </div>
          <div class="col-auto ml-2">
            <h3>{{ announcements.message }}</h3>
          </div>
        </card>
      </div>
    </div>

    <!-- Event Details-->
    <div class="row justify-content-center" id="event-details">
      <div class="col-md-6 order-md-0 mt-2">
        <div class="card">
          <!-- Card body -->
          <div class="card-body">
            <h3 class="inline col-md-10 text-base">
              <i class="fa fa-clipboard-list mr-2" />{{$t("tabloidEventLandingpage.aboutThisEvent")}}
            </h3>
            <h1 class="mb--2">{{ item.name }}</h1>
            <hr />

            <span
              id="expandable-description"
              :class="{
                'collapsed-text': textCollapsed && showDescriptionsExpand(),
              }"
            >
              <p class="text-left" style="margin-bottom: 0.5em">
                {{ item.description }}
              </p>
              <div
                style="margin-bottom: 0.5em"
                v-html="item.additional_description"
              ></div>
            </span>

            <a
              @click="textCollapsed = !textCollapsed"
              class="mt-2 link-view-more float-right"
              v-if="showDescriptionsExpand()"
            >
              <badge type="base" tag="a" size="md">
                <small style="font-weight: bolder">
                  <i v-if="textCollapsed" class="el-icon-sort-down" />
                  <i v-else class="el-icon-sort-up" />
                  {{
                    textCollapsed ? " Read more details" : " Read less details"
                  }}
                </small>
              </badge>
            </a>

            <div class="mt-5">
              <h3 class="inline col-md-10 text-base" style="margin-top: 1em">
                <i class="fa fa-tags mr-2" />{{$t("general.officialHashtags")}}
              </h3>
              <div
                v-for="(tag, index) in getTags()"
                :key="index"
                class="badge badge-default badge-pill mr-3"
                :style="{ backgroundColor: tag.color }"
              >
                #{{ tag.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 order-md-0 ml--1 mt-3">
        <card>
          <div class="row align-items-center">
            <div class="col ml-3">
              <span class="h6 surtitle text-muted"
                ><i class="fa fa-headset"></i>
                {{$t("tabloidEventLandingpage.organizedBy")}}
              </span>
              <span class="d-block h3">{{ item.organizer }}</span>
            </div>

            <template>
              <div class="col-auto mt-1">
                <div
                  class="icon icon-shape bg-gradient-base text-white rounded-circle shadow"
                >
                  <img
                    v-if="organizerPicture !== null"
                    class="avatar avatar-md rounded-circle bg-gradient-primary"
                    style="height: 42px; width: 42px"
                    :src="organizerPicture"
                  />
                  <i v-else class="fa fa-shapes" />
                </div>
              </div>
            </template>
          </div>
        </card>
        <!--Latest Announcement-->
        <card
          v-if="
            item?.show_latest_announcement &&
            announcements?.importance_type === 'normal' &&
            announcements?.message.length > 0
          "
          style="border-style: solid; border-color: #5e72e4; border-width: 2px"
        >
          <div class="row align-items-center">
            <div class="col-auto ml-2">
              <span class="h6 surtitle text-muted">
               <i class="ni ni-notification-70" />
              {{$t("general.announcement")}}
              </span>
            </div>
            <div class="col text-right">
              <small class="text-muted">
                <i class="fas fa-clock mr-1" />
                {{ displayAnnouncementTime(announcements.updated_at) }}
              </small>
            </div>
          </div>
          <div class="col-auto ml-2">
            <h3>{{ announcements.message }}</h3>
          </div>
        </card>
        <!--Event Agenda Section-->
          <div class="card order-3" v-if="item.event_agenda">
            <h3 class="card-title inline text-base ml-4 mt-2 mb-0">
              <i class="el-icon-s-management mr-1" />{{$t("general.detailedAgenda")}}
            </h3>

            <div class="card-body mt-0">
              <div class="row">
                <div class="col">
                  <el-tabs type="card">
                    <el-tab-pane
                      v-for="(_item, idx) in item?.event_agenda"
                      :key="_item + idx"
                    >
                      <span slot="label">
                        <h3 class="card-title mb--2 mt-2">{{ _item.title }}</h3>
                        <small class="mt-0">
                          {{ getAgendaLabel(_item) }}
                        </small>
                      </span>

                      <div class="d-flex text-wrap">
                        <div class="card-text" v-html="_item.content"></div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    
    <!-- BMIS Banner -->
    <div class="row justify-content-center" id="bmis-cta-banner">
      <div class="col-md-10">
        <card class="bg-gradient-primary">
          <!-- Card body -->
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted text-white">
                {{$t("general.seizeTheMoment")}}
              </h5>
            </div>
            <div class="col-auto mt-1">
              <img :src="logo" width="28" height="45" />
            </div>
          </div>
          <p class="text-sm mb--2">
            <span class="text-nowrap text-light"
              >{{$t("tabloidEventLandingpage.dontMissOutRegisterNow")}}
            </span>
          </p>
        </card>
      </div>
      </div>
    
    <!-- Media and Location-->
    <div class="row justify-content-center" id="event-media-location">
      <div class="col-md-10">
        <!-- Tabs-->
        <el-tabs v-model="activeTab">
          <el-tab-pane type="card"
            name="media-gallery"
            class="row justify-content-center mt-4 media-gallery"
            v-show="getImages().length > 0"
           >
            <span slot="label">
              <h2 class="display-6 text-center mt-2 mb-2">
                <i class="fa fa-video mr-1"/>
                {{$t("tabloidEventLandingpage.mediaGallery")}}
              </h2>
            </span>
            <div class="col-12" id="event-gallery-media">
              <!-- Video Section -->
              <div class="col-md-14" v-if="item.video_url">
                <card type="frame">
                  <div class="row align-content-end">
                    <div class="col-md-4 offset-md-4">
                      <base-button
                        block
                        type="base"
                        @click="modals.openVideo = true"
                        data-toggle="tooltip"
                        style="cursor: pointer"
                      >
                        <i class="fa fa-play" />{{$t("tabloidEventLandingpage.watchTrailer")}}
                      </base-button>
                    </div>
                  </div>
                </card>

                <!--Video Modal -->
                <modal
                  :show.sync="modals.openVideo"
                  size="lg"
                  gradient="primary"
                >
                  <h6
                    slot="header"
                    class="modal-title text-center text-white mt-1"
                  >
                    <i class="fa fa-tv" /> {{$t("tabloidEventLandingpage.promoTrailer")}}
                  </h6>

                  <base-video-you-tube-player
                    v-if="modals.openVideo"
                    :yt-video="item.video_url"
                  />
                  <div v-else class="col-md-12 mb-4">
                    <img
                      src="/videos/bmis-video-placeholder.jpg"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </modal>
              </div>
              <el-carousel
                :interval="4000"
                direction="horizontal"
                height="300px"
              >
                <el-carousel-item
                  v-for="(image, imgIdx) in getImages()"
                  :key="imgIdx"
                >
                  <el-image
                    @click="idx = imgIdx"
                    :src="image.src"
                    :alt="image.id"
                    class="rounded"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
              <vue-cool-lightbox
                :items="getImages()"
                :index="idx"
                @close="idx = null"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane type="card"
                        name="location"
                        class="row justify-content-center mt-1"
          >
            <span slot="label">
              <h2 class="display-6 text-center mt-2 mb-2">
                <i class="ni ni-pin-3 mr-1" />
                {{ getMapLabel() }}
              </h2>
            </span>
            <div class="col-md-12" id="event-happening" >
              <div
                class="col-md-12"
                v-if="
                  item.event_type === 'physical' ||
                  item.event_type === 'hybrid' ||
                  item.event_type === 'virtual'
                "
              >
                <card
                  v-if="
                    item.event_type === 'hybrid' ||
                    item.event_type === 'virtual'
                  "
                >
                  <div class="row align-items-center">
                    <div class="col-md-8">
                      <span class="h6 surtitle text-muted">
                        <i class="fa fa-satellite-dish" />
                        {{$t("evenTalkLandingpage.onlineEvent")}}
                      </span>
                      <h3 class="display-3 mb-0">{{$t("tabloidEventLandingpage.Join the online event via")}}</h3>
                    </div>
                    <div class="col-md-4 mt-2">
                      <a
                        :href="item.virtual_link"
                        target="_blank"
                        class="text-white"
                      >
                        <base-button block type="base">
                          <i class="fa fa-satellite-dish" />
                          {{ getVirtualLink(item.virtual_link) }}
                        </base-button>
                      </a>
                    </div>
                  </div>
                </card>
                <base-mapbox
                  :long="venue.long"
                  :lat="venue.lat"
                  :label="$t('editVenueItem.venueLocation')"
                  :search="false"
                  :draggable-pin="false"
                  :enable-shortcuts="true"
                  :scrollable="true"
                  :navigation-btn="true"
                  v-if="
                    venue.long &&
                    venue.lat &&
                    (item.event_type === 'physical' ||
                      item.event_type === 'hybrid' ||
                      item.event_type === 'Location')
                  "
                />
              </div>

                <div
                  class="col-md-12 mt--5"
                  v-else-if="item.event_type === 'tba'"
                >
                  <card>
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <span class="h6 surtitle text-muted"
                          ><i class="fas fa-bullhorn"></i>
                        {{$t("general.toBeAnnounced")}}
                        </span>
                        <h2 class="display-2 text-center">
                         {{$t("tabloidEventLandingpage.moreToComeStayTuned")}}
                        </h2>
                      </div>
                    </div>
                  </card>
                </div>
            </div>
          </el-tab-pane>

          
          <el-tab-pane id="content-hub" name="content-hub">
        <!--Content Hub section--->
        <span slot="label">
            <h2 class="display-6 text-center mt-2 mb-2">
              <i class="fa fa-folder mr-2" />
              {{$t("tabloidEventLandingpage.contentHub")}}
            </h2>
          </span>
          <!-- Content Section -->
          <div id="event-document-asset"
               v-if="getDocuments().length > 0">
            <div class="row justify-content-center">
              <div class="col-md-3" v-for="(doc, idx) in getDocuments()" :key="idx">
                <!-- PDF Viewer -->
                <base-stats-card
                    title="$t('tabloidEventLandingpage.totalTicketOrders')"
                    type="gradient-base"
                >
<!--                  <pdf v-if="!isDocPDF(doc.mimeType)" :src="doc.src" :page="1" style="width: 100%;"/>-->
                  <img class="card-img-top"  alt="Image placeholder" src="/img/placeholders/bmis-content-asset-docs.svg">
                  <div class="text-center mt-2 mb--3">
                    <small class="text-muted text-center">
                      <badge type="base" tag="a" :href="doc.src" size="lg">
                        <slot name="icon"
                        ><i class="fa fa-download text-base mr-1"/> {{$t("tabloidEventLandingpage.downloadAsset")}}
                        </slot>
                      </badge>
                    </small>
                  </div>

                </base-stats-card>
              </div>
            </div>
          </div>
        </el-tab-pane>
        </el-tabs>
      </div>
      
    </div>

    <!-- Ticketing Modal -->
    <div
      class="col-md-4 offset-md-4"
      id="bmis-ticketing-modal"
      v-if="onlyBookButton"
    >
      <book-button-component
        :item="item"
        :modals="modals"
        @addTicket="addTicket($event)"
      ></book-button-component>
    </div>

    <!-- FAQs Section -->
      <div id="event-faqs" class="row justify-content-center mt-5">
        <div class="col-md-10" v-if="item.event_faqs !== null">
          <h2 class="display-1 text-center" >
            FAQs
          </h2>
          <card>
            <el-collapse accordion>
              <el-collapse-item v-for="(faq, idx) in item.event_faqs"
                                :key="idx"
                                :name="faq.name"
              >
                <template slot="title">
                  {{ faq.title }}
                </template>
                <div v-html="faq.content"></div>
              </el-collapse-item>
            </el-collapse>
          </card>
        </div>
      </div>
  </div>
</template>

<script>
import {
  Button,
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Tooltip,
  Skeleton,
  SkeletonItem,
  Collapse,
  CollapseItem,
  InputNumber,
  Steps,
  Step,
  Tabs,
  TabPane,
  Timeline,
  TimelineItem,
} from "element-ui";
import BaseVideoYouTubePlayer from "@/components/Media/BaseVideoYouTubePlayer.vue";
import BaseMapbox from "@/components/Mapbox/BaseMapbox.vue";
import Card from "../Cards/Card.vue";
import Badge from "@/components/Badge";
import { TimeLine, TimeLineItem, LoadingPanel } from "@/components";
import Vue from "vue";
import VueGtag from "vue-gtag";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseSwitchVue from "../BaseSwitch.vue";
import BookButtonComponent from "./BookButtonComponent.vue";

const moment = require("moment-timezone");

export default {
  name: "BaseEventLandingPage",
  components: { 
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [InputNumber.name]: InputNumber,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [TimeLine.name]: TimeLine,
    [TimeLineItem.name]: TimeLineItem,
    [BaseSwitchVue]: BaseSwitchVue,
    BaseMapbox,
    BaseVideoYouTubePlayer,
    Card,
    BookButtonComponent,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    modals: {
      type: Object,
      default: null,
    },
    hasBookButton: {
      type: Boolean,
      default: true,
    },
    onlyBookButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: "media-gallery",
      freePersonalizedGuests: [],
      announcements: {
        message: "",
      },
      attendee: {
        name: "",
        email: "",
        special_requirements: this.hasDietaryRequirements() ? "" : undefined,
        type: "attendees",
      },
      tags: [],
      venue: {},
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      textCollapsed: true,
      showSponsors: false,
      showSpeakers: false,
      showAnnouncement: false,
      heroImageLoaded: false,
      activeStep: 0,
      idx: 0,
      loading: false,
      
    };
  },
  created() {
    this.setItem();
    this.getTickets();
    this.getTags();
    this.displayLatestAnnouncement();

    this.items = {
      item: this.item,
      tags: this.tags,
      dates: [],
    };
  },
  computed: {
    user() {
      let me = null;
      try {
        // Get User Profile
        me = this.$store.getters["profile/me"];
      } catch (error) {
        // Notification
       this.$notify({
          type:"danger",
          message:this.$t('tabloidEventLandingpage.oopsSomethingWentWrongWithYourAccount'),
        });
      }
      // return user object
      return me;
    },
    logo() {
      return process.env.VUE_APP_DEFAULT_LOGO;
    },
    organizerPicture() {
      if (
        this.item?.user?.profile_image.includes("http://") ||
        this.item?.user?.profile_image.includes("https://")
      ) {
        return this.item?.user?.profile_image;
      }
      return this.item?.user?.profile_image
        ? process.env.VUE_APP_AWS_S3_URL + this.item?.user?.profile_image
        : null;
    },
    eventStartDateTime() {
      return moment(this.items.item.start_at).format("ddd, DD MMM YY");
    },
    eventStartDateTimeShort() {
      return moment(this.items.item.start_at).format("ddd, DD MMM");
    },
    eventEndDateTime() {
      return moment(this.items.item.end_at).format("ddd, DD MMM YY");
    },
    eventEndDateTimeShort() {
      return moment(this.items.item.end_at).format("ddd, DD MMM");
    },
    eventTotalDuration() {
      const startAt = moment(this.items.item.start_at);
      const endAt = moment(this.items.item.end_at);

      return endAt.diff(startAt, "days");
    },
    eventStartTime() {
      return (
        moment(this.items.item.start_at)
          // .tz(this.items.item.timezone)
          .format("LT")
      );
    },
    eventEndTime() {
      return (
        moment(this.items.item.end_at)
          // .tz(this.items.item.timezone)
          .format("LT")
      );
    },
    localTimezone() {
      const tz = this.timezone ?? this.user?.timezone;
       return (
      this.$t('tabloidEventLandingpage.theTimesForTheEventsAreShownInYourOwnLocalTimeZone') + " (" + tz + ")"
       );
    },
    originalTimezone() {
     return (
      this.$t('tabloidEventLandingpage.theTimesForTheEventsAreShownInYourOwnLocalTimeZone') + " (" + this.items.item.timezone + ")"
       );
    },
    isHybridSameTimezone() {
      return (
        this.items.item.event_type === "hybrid" &&
        this.items.item.timezone === this.timezone
      );
    },
    localStartTime() {
      const originalTime = moment.tz(
        this.items.item.start_at,
        this.items.item.timezone
      );
      return originalTime.clone().tz(this.timezone).format("LT");
    },
    localEndTime() {
      const originalTime = moment.tz(
        this.items.item.end_at,
        this.items.item.timezone
      );
      return originalTime.clone().tz(this.timezone).format("LT");
    },
    eventPublicURL() {
      const itemId = _.isEmpty(this.item.hash_code)
        ? this.item.id
        : this.item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/event/" + itemId;
    },
    eventShortDescription() {
      return this.item.description
        ? this.item.description.substring(0, 140) + "..."
        : " ";
    },
    virtualLinkMessage() {
      if (this.item.virtual_link) {
        return (
          this.$t('tabloidEventLandingpage.youCanAttendEventThroughThisLink') +
          this.item.virtual_link +
          "%0D%0A" +
          "%0D%0A"
        );
      } else return "%0D%0A";
    },
  },
  methods: {
    setItem() {
      this.tags = this.item?.tags;
      this.venue = this.item.venues ?? {};
    },
    addTicket(attendee) {
      this.$emit("addTicket", attendee);
    },
    setTicketToAttendee(ticket) {
      this.attendee.item_id = this.item.id;
      if (ticket?.selectedQuantity) {
        this.attendee.ticket_id = ticket.id;
        this.attendee.price = ticket.metadata.price;
        this.attendee.final_price = ticket.metadata.final_price;
        this.attendee.quantity = ticket.selectedQuantity;
        this.attendee.ticket_name = ticket.name;
        this.attendee.personalized_ticket = ticket.metadata.personalized_ticket;
        if (
          ticket.metadata.personalized_ticket === 1 &&
          (ticket.metadata.final_price === 0 || ticket.metadata.price === 0)
        ) {
          this.freePersonalizedGuests = [];
          for (let i = 1; i <= ticket.selectedQuantity; i++) {
            this.freePersonalizedGuests.push(
              JSON.parse(JSON.stringify(this.attendee))
            );
          }
        } else {
          this.freePersonalizedGuests = [];
        }
      } else {
        this.attendee.ticket_id = undefined;
        this.attendee.price = undefined;
        this.attendee.final_price = undefined;
        this.attendee.quantity = undefined;
        this.attendee.ticket_name = undefined;
        this.attendee.personalized_ticket = undefined;
        this.freePersonalizedGuests = [];
      }
    },
    getTickets() {
      // we used to return only available tickets
      // let availableTickets = [];
      // this.item?.event_tickets.forEach((ticket) => {
      //   if (
      //     moment().isBetween(
      //       ticket.metadata.start_sale_at,
      //       ticket.metadata.end_sale_at,
      //       "day",
      //       "[]"
      //     ) &&
      //     (ticket?.metadata?.sold_quantity <=
      //       ticket?.metadata?.total_quantity ||
      //       ticket?.metadata?.total_quantity === -1)
      //   ) {
      //     availableTickets.push(ticket);
      //   }
      // });
      // return availableTickets;
      return this.item?.event_tickets || [];
    },
    ticketIsAvailable(ticket) {
      return (
        moment().isBetween(
          ticket.metadata.start_sale_at,
          ticket.metadata.end_sale_at,
          "day",
          "[]"
        ) &&
        (ticket?.metadata?.sold_quantity <= ticket?.metadata?.total_quantity ||
          ticket?.metadata?.total_quantity === -1)
      );
    },
    ticketIsSoldOut(ticket) {
      return !(
        ticket?.metadata?.sold_quantity <= ticket?.metadata?.total_quantity ||
        ticket?.metadata?.total_quantity === -1
      );
    },
    ticketIsNotAvailableAnyMore(ticket) {
      return moment().isAfter(
        ticket.metadata.start_sale_at,
        ticket.metadata.end_sale_at,
        "day",
        "[]"
      );
    },
    ticketIsNotAvailableYet(ticket) {
      return moment().isBefore(
        ticket.metadata.start_sale_at,
        ticket.metadata.end_sale_at,
        "day",
        "[]"
      );
    },
    getTags() {
      return this.tags;
    },
    getAvailability(available) {
      return moment(available).format("Do MMM YYYY, h:mm:ss a");
    },
    getHeroImage() {
      if (
        this.items.item.hero_image.includes("http://") ||
        this.items.item.hero_image.includes("https://")
      ) {
        return this.items.item.hero_image;
      }
      return process.env.VUE_APP_AWS_S3_URL + this.items.item.hero_image;
    },
    getImages() {
      return this.items.item?.additional_images.map((image) => ({
        id: image.id,
        src: process.env.VUE_APP_AWS_S3_URL + image.additional_image,
      }));
    },
    openModal() {
      try {
        // Send action to Google Analytics
        this.$gtag.event("BMIS-cta-open-ticket-modal", {
          eventId: this.item.id,
        });
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message:this.$t('tabloidEventLandingpage.oopsSomethingWentWrongWithThisAction'),
        });
      }

      // reset step to 0
      this.activeStep = 0;

      // reset selectedQuantity to 0
      this.getTickets().forEach((ticket) => {
        ticket.selectedQuantity = 0;
      });
      this.freePersonalizedGuests = [];
      this.attendee = {
        name: "",
        email: "",
        special_requirements: this.hasDietaryRequirements() ? "" : undefined,
        type: "attendees",
      };
      this.loading = false;
      this.modals.openBooking = true;
    },
      getDocuments() {
      return this.items.item?.additional_docs.map((doc) => ({
        id: doc.id,
        src: process.env.VUE_APP_AWS_S3_URL + doc.additional_asset,
        mimeType: doc.type,
      }));
    },
    isDocPDF(mime) {
      const regex = /pdf/i;

      if(regex.test(mime)) {
        console.log("String contains pdf");
        return true;
      } else {
        console.log("String does not contain pdf");
        return false;
      }
    },
    showDescriptionsExpand() {
      return (
        this.item?.description?.length > 1000 ||
        this.item?.additional_description?.length > 1000
      );
    },
    getHashtags() {
      const tagsName = ["bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(tag.name);
      });
      return tagsName.join();
    },
    getHashtagsForEmail() {
      const tagsName = [" #bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(" #" + tag.name);
      });
      return tagsName.join();
    },
    getVirtualLink(link) {
      const zoomPattern = /zoom/;
      if (zoomPattern.test(link)) {
        return "Zoom";
      }

      const meetPattern = /meet/;
      if (meetPattern.test(link)) {
        return "Google Meet";
      }

      const teamsPattern = /teams/;
      if (teamsPattern.test(link)) {
        return "Microsoft Teams";
      }

      return "Virtual Link";
    },
    getCalendarFile() {
      this.$ics.removeAllEvents();
      this.$ics.addEvent(
        navigator.language === "zz-ZZ" ? "en-AU" : navigator.language,
        this.item.name,
        this.item.description,
        this.item.venues ? this.item.venues.address_name : "",
        this.item.start_at,
        this.item.end_at,
        this.eventPublicURL,
        {},
        "",
        ""
      );
      this.$ics.download(this.item.name);
    },
     getMapLabel() {
       switch (this.item.event_type) {
        case this.$t('tabloidEventLandingpage.virtual'):
          return this.$t('tabloidEventLandingpage.virtualLocation');
          break;
        case  this.$t('tabloidEventLandingpage.physical'):
          return this.$t('tabloidEventLandingpage.venueLocation');
          break;
        case this.$t('tabloidEventLandingpage.hybrid'):
          return this.$t('tabloidEventLandingpage.virtualAndVenueLocation');
          break;
        default:
          return this.$t('tabloidEventLandingpage.whereToFindUs');
      }
    },
    getAgendaLabel(val) {
      const tabDate = val.date ?? val.start_at;
      return " ● " + moment(tabDate).format("ddd, D MMM @ HH:mm");
    },
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.eventPublicURL);
        swal.fire({
             title: this.$t('tabloidEventLandingpage.copiedPublicEventLink'),
            html:
              this.$t('general.youCanUseThisLinkToShareItWithWideGroup') + "<br>"  + "</hr>"  +
              "<br><b>" + this.$t('tabloidEventLandingPage.publicEventLink')+ ":</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
              this.eventPublicURL() +
              "</button> <br></hr>",
            type: "success",
            confirmButtonClass: "btn btn-outline-danger btn-fill",
            confirmButtonText: this.$t('general.close'),
            buttonsStyling: false,
            icon: "success",
          });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    hasSpecialRequirements() {
      return true;
    },
    hasDietaryRequirements() {
      // return this.item?.category?.name === "Food & Drinks";
      return this.hasSpecialRequirements();
    },
    async displayLatestAnnouncement() {
      let params = {
        // ...{ sort: "-created_at" },
        filter: {
          ...{ item_id: this.item.id },
        },
      };
      await this.$store.dispatch("announcements/list", params).then(() => {
        const announcements = this.$store.getters["announcements/list"];
        if (announcements?.length > 0) {
          this.announcements = announcements[0];
        } else {
          this.announcements.message = "";
        }
      });
    },
    displayAnnouncementTime(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>

<style scoped>
#mantra-banner {
  width: 60vw !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.el-tabs--card >>> .el-tabs__header {
  box-shadow: 0 0 0 0 !important;
  border: 0 !important;
  padding-right: 0;
  padding-left: 0;
  overflow: auto !important;
  white-space: nowrap !important;
}
</style>