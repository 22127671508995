import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/items`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id, params) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    },
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/items/${id}`, options).then(response => {
      return jsona.deserialize(response.data);
    });
}

function getPublic(id, params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type' : 'application/vnd.api+json'
    },
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/public/items/${id}`, options).then(response => {
      return jsona.deserialize(response.data);
    });
}

function add(item) {
  const payload = jsona.serialize({
    stuff: item,
    includeNames: ["categories"]
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/items?include=category,tags`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(item) {
  delete item.hero_image;

  const payload = jsona.serialize({
    stuff: item,
    includeNames: ["tickets"]
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .patch(`${url}/items/${item.id}?include=category,tags,tickets`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.delete(`${url}/items/${id}`, options);
}

function upload(item, image) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", image);

  return axios
    .post(`${url}/uploads/items/${item.id}/image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}

function getAddress(center, access_token) { 
  return axios
    .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${center[0]},${center[1]}.json?access_token=${access_token}`)
    .then(response => {return response.data});
}

function contact(payload) {
  return axios.post(`${url}/email/contact-organizer`, payload);
}

export default {
  list,
  get,
  getPublic,
  getAddress,
  add,
  update,
  destroy,
  upload,
  contact
};
