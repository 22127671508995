<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />

    <div class="row mt-3">
      <div class="col-xl-6 order-xl-1">

        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("editUserPage.editUser") }}</h3>
            </div>
          </div>

          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <div class="form-group">
                <!-- <label class="form-control-label"> {{$t("editUserPage.picture")}} </label>sdas
                <div v-if="image" class="profile-image card-img pb-4">
                  <img
                    :src="`${user.profile_image}`"
                    class="profile-image base-profile-image"
                  />
                </div>
                <div v-else class="profile-image">
                  <img src="/img/placeholder.jpg" class="base-profile-image" />
                </div> -->
<!--                <div class="image-upload">-->
<!--                  <base-button-->
<!--                    v-if="image"-->
<!--                    type="button"-->
<!--                    class="btn btn-sm btn-danger"-->
<!--                    @click="removeImage"-->
<!--                  >-->
<!--                    <span>-->
<!--                      <i class="fa fa-times" />-->
<!--                      Remove-->
<!--                    </span>-->
<!--                  </base-button>-->
<!--                  <base-button type="button" class="btn btn-sm btn-primary">-->
<!--                    <label v-if="!image" for="imageInput" class="mb-0"-->
<!--                      >Select image</label-->
<!--                    >-->
<!--                    <label v-else for="imageInput" class="mb-0">Change</label>-->
<!--                    <input-->
<!--                      id="imageInput"-->
<!--                      ref="imageInput"-->
<!--                      accept="image/*"-->
<!--                      type="file"-->
<!--                      style="display: none"-->
<!--                      @change="onSelectFile"-->
<!--                    />-->
<!--                  </base-button>-->
<!--                </div>-->
              </div>
<!--              <validation-error :errors="apiValidationErrors.attachment" />-->

              <base-input :label="$t('editUserPage.name')"
                prepend-icon="fas fa-user"
                :placeholder="$t('editUserPage.yourName')"
                v-model="user.name"
              />
              <validation-error :errors="apiValidationErrors.name" />

              <base-input label="Email"
                prepend-icon="fas fa-envelope"
                placeholder="Email"
                v-model="user.email"
              />
              <validation-error :errors="apiValidationErrors.email" />

              <hr/>

              <base-input :label="$t('editUserPage.role')">
                <el-select
                  name="role"
                  v-model="user.roleId"
                  prepend-icon="fa fa-credit-card"
                >
                  <el-option
                    v-for="role in available_roles"
                    :key="role.id"
                    :value="role.id"
                    :label="role.name"
                  >
                  </el-option>
                </el-select>
              </base-input>

              <base-input :label="$t('addUserPage.password')"
                v-model="user.password"
                name="new_password"
                class="mb-3"
                prepend-icon="fa fa-key"
                @input="sanitizePassword"
                :placeholder="$t('addUserPage.newPassword')"

              />
              <validation-error :errors="apiValidationErrors.password" />

              <base-input :label="$t('editUserPage.confirmPassword')"
                  :placeholder="$t('editUserPage.confirmPassword')"
                  v-model="user.password_confirmation"
                name="confirm_password"
                autocomplete="on"
                class="mb-3"
                prepend-icon="fa fa-key"
                @input="sanitizePassword"
              />
              <validation-error :errors="apiValidationErrors.password_confirmation"/>

              <div class="my-4 mt-5 text-right">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  <i class="fa fa-edit mr-1"/> {{ $t("editUserPage.updateUser") }}
                </base-button>
              </div>
            </form>
          </div>
        </card>

      </div>

      <div class="col-xl-6 order-xl-1">

        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("editUserPage.editUserConfigs") }}</h3>
            </div>
          </div>

          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleConfigs">

              <base-input :label="$t('editUserPage.paymentGatewayService')">
                <el-select
                    name="gateway"
                    v-model="payment_gateway"
                >
                  <el-option
                      v-for="gateway in payment_gateways"
                      :key="gateway.id"
                      :value="gateway.id"
                      :label="gateway.name"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <validation-error :errors="apiValidationErrors.payment_gateway" />

              <base-input  :label="$t('editUserPage.snipcartPaymentToken')"
                           type="token"
                           name="snipcart_token"
                           autocomplete="on"
                           class="mb-3"
                           prepend-icon="fa fa-credit-card"
                           :placeholder="$t('editUserPage.addOrganizersSnipcartToken')"
                           v-model="user.metadata.snipcart_token"
              />
              <validation-error :errors="apiValidationErrors.snipcart_token" />

              <hr>
              <base-input :label="$t('userPreferencesCard.timeZone')">
                <el-select
                    v-model="user.timezone"
                    filterable
                    prepend-icon="fas fa-user"
                    :placeholder="$t('general.select')+'...'"
                >
                  <el-option
                      v-for="timezone in allTimezones"
                      :key="timezone"
                      :label="timezone"
                      :value="timezone"
                  >
                  </el-option>
                </el-select>
              </base-input>

              <base-input label="Currency">
                <el-select
                    v-model="user.currency"
                    filterable
                    prepend-icon="fas fa-user"
                    :placeholder="$t('general.select')+'...'"
                >
                  <el-option
                      v-for="currency in allCurrencies"
                      :key="currency"
                      :label="currency"
                      :value="currency.toLowerCase()"
                  >
                  </el-option>
                </el-select>
              </base-input>

              <div class="my-4 mt-5 text-right">
                <base-button
                    type="button"
                    class="btn btn-sm btn-primary"
                    native-type="submit"
                >
                  <i class="fa fa-edit mr-1"/> {{ $t("editUserPage.updateUserConfigs") }}
                </base-button>
              </div>
            </form>
          </div>
        </card>

      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";

import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BackButton from "@/components/BackButton.vue";

import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import currencies from 'world-currencies';
import moment from "moment-timezone";

export default {
  name: "EditUserDetails",
  layout: "DashboardLayout",
  components: {
    BaseInput,
    BaseButton,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    BackButton
  },
  mixins: [formMixin],
  data() {
    return {
      roles: [],
      available_roles: [],
      user: {
        type: "users",
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        profile_image: null,
        currency: null,
        metadata: {
          slack_notifications: null,
          slack_token: null,
          snipcart_token: null,
        },
        roleId: null,
      },
      relationshipNames: ["roles"],
      image: null,
      payment_gateway: 'snipcart',
      payment_gateways:  [
        { id: 1, name: 'snipcart' },
        { id: 2, name:'stripe' }
      ],
    };
  },
  computed: {
    allTimezones() {
      return moment.tz.names();
    },
    allCurrencies() {
      return Object.keys(currencies);
    },
  },
  created() {
    this.init();
    this.getRoles();
  },
  methods: {
    async init() {
      try {
        const id = this.$route.params.id;

        const params = {
          include: "roles",
        };

        await this.$store.dispatch("users/get", { id, params }).then( () =>
        {
          const response = this.$store.getters["users/user"];

          this.user.name    = response.name;
          this.user.email   = response.email;
          this.user.roles   = response.roles;
          this.user.roleId  = response.roles[0].id;

          this.user.timezone = response.timezone;
          this.user.currency = response.currency;

          this.user.metadata.slack_notifications  = response.slack_notifications
          this.user.metadata.snipcart_token       = response.snipcart_token

        });

      } catch (err) {
        this.$notify({
          type: "danger",
         message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(err.response.data.errors);
      }
    },
    async getRoles() {
      try {
        await this.$store.dispatch("roles/list").then(() => {
          this.available_roles = this.$store.getters["roles/dropdown"]
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({
        name: "List Users"
      });
    },
    removeImage() {
      this.image = null;
    },
    async handleSubmit() {
      if (this.$isDemo == 1 && !this.isSuperUser()) {
        this.$notify({
          type: "danger",
          message: this.$t('editUserPage.youAreNotAllowedNotChangeDataOfDefaultUsers')+'.',
        });
        return;
      }

      // clean-up redundant variables
      delete this.user?.profile_image;

      try {
        const payload = {
          id: this.$route.params.id,
          type: 'users',
          name : this.user.name,
          email : this.user.email,
          metadata: {
            slack_token         : this.user.metadata.slack_token,
            slack_notifications : this.user.metadata.slack_notifications,
          },
          roles : [{
            type: "roles",
            id: this.user.roleId.toString(),
          }],
          relationshipNames: this.relationshipNames
        };

        if(!_.isEmpty(this.user.password)) {
          payload.password = this.user.password;
          payload.password_confirmation = this.user.password_confirmation;
        }

        await this.$store.dispatch("users/update", payload).then(() => {
          this.$notify({
            type: "success",
            message: this.$t('editUserPage.userUpdatedSuccessfully')+'.',
          });
        });

        // this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async handleConfigs() {
      if (this.$isDemo == 1 && !this.isSuperUser()) {
        this.$notify({
          type: "danger",
          message: this.$t('editUserPage.youAreNotAllowedNotChangeDataOfDefaultUsers')+'.',
        });
        return;
      }

      try {
        const payload = {
          id: this.$route.params.id,
          type: 'users',
          metadata: {
            snipcart_token: this.user.metadata.snipcart_token
          }
        };

        if(!_.isEmpty(this.user?.timezone)) {
          payload.timezone = this.user.timezone;
        }

        if(!_.isEmpty(this.user?.currency)) {
          payload.currency = this.user.currency;
        }

        await this.$store.dispatch("users/update", payload).then( () =>
        {
          this.$notify({
            type: "success",
            message: this.$t('editUserPage.userUpdatedSuccessfully')+'.',
          });
        });

        // this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.user.profile_image = e.target.result;
        this.image = file;
      };
      reader.readAsDataURL(file);
    },
    sanitizePassword() {
      // Replace invalid characters or spaces ( allow only: full stop, underscore and exclamation mark to allow underscore )
      this.user.password = this.user?.password?.replace(/[^a-zA-Z0-9._!]/g, '');
      this.user.password_confirmation = this.user?.password_confirmation?.replace(/[^a-zA-Z0-9._!]/g, '');
    }
  }
}
</script>
