import Vue from "vue";
import store from "@/store";
import moment from "moment-timezone";

import { upperFirst } from "lodash";
import { setOptions, bootstrap } from 'vue-gtag'

/**
 * Global Mixins: This is a different type of mixin that is defined in the Main.js file of any
 * Vue project. It affects all Vue components in an application so the core Vue team advises that
 * it be used with caution and tested well before any changes are added or included
 */
Vue.mixin({
    methods: {
        // Permissions
        isDeveloper() { // Devs Only
            return this.getUserRole() === 'developer'
        },
        isSuperUser() { // Back-office
            const suRoles = ['admin', 'moderator'];
            const loggedRole = this.getUserRole(); //member

            return suRoles.includes(loggedRole);
        },
        isGuestUser() { // Member
            const freemiumRoles = ['guest', 'member'];
            const loggedRole = this.getUserRole();

            return freemiumRoles.includes(loggedRole);
        },
        isFreeUser() { // Creator
            const freemiumRoles = ['member', 'creator'];
            const loggedRole = this.getUserRole();

            return freemiumRoles.includes(loggedRole);
        },
        isFreemiumUser() { // Creator-plus
            const freemiumRoles = ['creator-plus'];
            const loggedRole = this.getUserRole();

            return freemiumRoles.includes(loggedRole);
        },
        isPremiumUser() { // Organizer
            const premiumRoles = ['moderator', 'organizer', 'planner'];
            const loggedRole = this.getUserRole();

            return premiumRoles.includes(loggedRole);
        },
        isProfessionalUser() { // Planner
            const premiumRoles = ['admin', 'planner'];
            const loggedRole = this.getUserRole();
            return premiumRoles.includes(loggedRole);
        },
        isCustomUser() { // Custom
            const customRoles = ['admin', 'agency', 'reseller'];
            const loggedRole = this.getUserRole();

            return customRoles.includes(loggedRole);
        },
        // Methods
        getUserRole: function (me = null) {
            try {
                me = store.getters["profile/me"];
            } catch (error) {
                console.error('Fetching user profile failed, please try to logout/login again -', error);
            }
            return me?.roles[0]?.name ?? 'guest';
        },
        getUserCurrency(me = null) {
            try {
                me = store.getters["profile/me"];
            } catch (error) {
                console.error('Fetching user profile failed, please try to logout/login again -', error);
            }
            console.debug('Get User Currency', me?.currency)

            return me?.currency ?? process.env.VUE_APP_DEFAULT_CURRENCY_CODE;
        },
        getCurrencySymbol() {
            const currency = this.getUserCurrency();

            switch(currency) {
                case 'eur': //  (Euro) → `€`
                    return "€";
                case 'usd': //  (US Dollar) → `$`
                    return "$";
                case 'aud': //  (Australian Dollar) → `A$`
                    return "A$";
                default: //  (Australian Dollar) → `A$`
                    return "A$";
            }
        },
        getTicketingFinalPercentage() {
            // Ticketing Fees
            let finalPercentageFee  = 0;
            let finalFixedFee       = 0;
            if (this.isPremiumUser() ) {
                finalPercentageFee  = 3.49;   // 3.49 %
                finalFixedFee       = 0.89;     // 0.89 cent
            } else if (this.isProfessionalUser() ) {
                finalPercentageFee  = 2.99;   // 2.99 %
                finalFixedFee       = 0.59;     // 0.59 cent
            } else if (this.isCustomUser() ) {
                finalPercentageFee  = 1.49;   // 1.49 %
                finalFixedFee       = 0.29;     // 0.29 cent
            } else {
                // Default fees (fallback)
                finalPercentageFee  = 3.99;   // 3.99 %
                finalFixedFee       = 0.99;     // 0.99 cent
            }
            return {
                percentageFee: finalPercentageFee,
                fixedFee: finalFixedFee,
            };
        },
        getPaymentPortal: function () {
            return process.env.VUE_APP_BASE_URL + '/users/user-profile';
        },
        getCustomerPortal: function () {
            const me = store.getters["profile/me"];
            return process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL + '?prefilled_email=' + me.email ?? '#';
        },
        getCountriesList() {
            const shortlistCountries = moment.tz.countries();
            const countryName = new Intl.DisplayNames(['en'], {type: 'region' });

            let fullListCountries= [];
            _.forEach(shortlistCountries, function(shortCountry) {
                fullListCountries.push(countryName.of(shortCountry));
            })
            return fullListCountries;
        },
        getEventFullStatusTypes(eventType) {
            switch(eventType) {
                case 'tba':
                    return "To-Be Announced"
                case 'physical':
                    return "In-Person"
                default:

                return upperFirst(eventType);
            }
        },
        setGoogleAnalytics: function (userKey) {
            if (userKey) {
                // Users G-Accounts
                setOptions({
                    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
                    includes: [
                        { id: userKey }
                    ]
                })
            } else {
                // Global App G-Account
                setOptions({
                    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS }
                })
            }
        },
    }
});