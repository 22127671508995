var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"additional_meta",on:{"submit":function($event){$event.preventDefault();return _vm.updateUserInfos.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h1',[_vm._v(_vm._s(_vm.$t("userPreferencesCard.updateUserPreferences")))])]),_c('div',{staticClass:"card-body"},[_c('base-input',{attrs:{"label":_vm.$t('userPreferencesCard.timeZone'),"required":""}},[_c('el-select',{attrs:{"filterable":"","prepend-icon":"fas fa-user","placeholder":_vm.$t('general.select')+'...'},model:{value:(_vm.user.timezone),callback:function ($$v) {_vm.$set(_vm.user, "timezone", $$v)},expression:"user.timezone"}},_vm._l((_vm.allTimezones),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.timezone}}),_c('base-input',{attrs:{"label":"Currency","required":""}},[_c('el-select',{attrs:{"filterable":"","prepend-icon":"fas fa-user","placeholder":_vm.$t('general.select')+'...'},model:{value:(_vm.user.currency),callback:function ($$v) {_vm.$set(_vm.user, "currency", $$v)},expression:"user.currency"}},_vm._l((_vm.allCurrencies),function(currency){return _c('el-option',{key:currency,attrs:{"label":currency,"value":currency.toLowerCase()}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.currency}})],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h1',[_vm._v(_vm._s(_vm.$t("userPreferencesCard.updateUserNotifications")))])]),_c('div',{staticClass:"card-body"},[_c('base-premium-overlay',{attrs:{"enabled":_vm.isFreeUser()}},[_c('div',[_c('base-input',{attrs:{"label":_vm.$t('userPreferencesCard.enableEmailNotification')}},[_c('base-switch',{staticClass:"mr-1",attrs:{"description":{
                      show: true,
                      content:
                        _vm.$t('userPreferencesCard.receiveRealTimeNotificationForActions'),
                    }},model:{value:(_vm.email_notifications),callback:function ($$v) {_vm.email_notifications=$$v},expression:"email_notifications"}})],1),(_vm.email_notifications)?_c('base-input',{staticClass:"mb-3",attrs:{"type":"token","name":"slack_token","label":_vm.$t('userPreferencesCard.emailNotification'),"autocomplete":"on","prepend-icon":"fas fa-envelope","placeholder":_vm.$t('userPreferencesCard.emailNotificationList')},model:{value:(_vm.email_list),callback:function ($$v) {_vm.email_list=$$v},expression:"email_list"}}):_vm._e(),_c('base-input',{attrs:{"label":_vm.$t('userPreferencesCard.enableNotificationInSlackChannel')}},[_c('base-switch',{staticClass:"mr-1",attrs:{"description":{
                      show: true,
                      content:
                        _vm.$t('userPreferencesCard.receiveRealTimeNotificationForActions'),
                    }},model:{value:(_vm.slack_notifications),callback:function ($$v) {_vm.slack_notifications=$$v},expression:"slack_notifications"}})],1),_c('base-premium-overlay',{attrs:{"enabled":_vm.isFreeUser(),"badge-label":_vm.$t('userPreferencesCard.comingSoon'),"badge-style":"warning","badge-message":_vm.$t('userPreferencesCard.thisIntegrationWillBeReleasedSoonFeatureIsUnderDevelopment')}},[(_vm.slack_notifications)?_c('base-input',{staticClass:"mb-3",attrs:{"type":"token","name":"slack_token","label":_vm.$t('userPreferencesCard.slackNotificationWebhook'),"autocomplete":"on","prepend-icon":"fab fa-slack","placeholder":_vm.$t('userPreferencesCard.slackNotificationToken'),"disabled":_vm.isFreeUser()},model:{value:(_vm.slack_token),callback:function ($$v) {_vm.slack_token=$$v},expression:"slack_token"}}):_vm._e()],1)],1)])],1)])]),_c('div',{staticClass:"col-md-12"},[_c('card',[_c('div',{staticClass:"my-4 justify-content-end"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit"}},[_c('span',{staticClass:"btn-inner--icon ml-1 mr-1 mt-0"},[_c('i',{staticClass:"fa fa-save"})]),_vm._v(" "+_vm._s(_vm.$t('userPreferencesCard.updateDetails'))+" ")])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }