<template>
  <div class="container-fluid">
    <!-- Base Booking CTA -->
    <base-button
        id="base-cta-ticket-booking"
        data-testid="base-cta-ticket-booking"
        type="danger"
        @click="openModal()"
        data-toggle="tooltip"
        class="mx-auto"
        :class="getCustomBookColor()"
        style="cursor: pointer;"
        :style="{
        background: item.book_button_color,
        'border-color': item.book_button_color,
      }"
        block
    >
      <i v-if="item.custom_book_button_label" class="el-icon-s-ticket" />
      <img
        v-else
        src="/img/brand/bmis-default-logo-cta.png"
        style="max-width: 1.3rem; max-height: 1.2rem; padding-right: 10px"
      />
      <span v-if="item.custom_book_button_label && item.book_button_label">
        {{ item.book_button_label }}
      </span>
      <span v-else>
        {{ $t("bookButtonComponent.bookTickets") }}
      </span>
    </base-button>
    <!-- ./ Base Booking CTA -->


    <!-- Modal Content-->
    <modal
      :show.sync="modals.openBooking"
      size="lg"
      :showClose="!onlyBookButton"
      :modal-classes="onlyBookButton ? 'full-screen-modal' : ''"
    >
      <template slot="header" v-if="!loading">
        <a href="https://www.bookmeinscotty.com">
          <img
            v-if="onlyBookButton"
            src="/img/brand/bmis-default-full-logo_italic_bck.png"
            alt="Sidebar logo"
            style="width: 10rem"
          />
        </a>
        <h3 slot="header" class="modal-title mt-1 ml-2" id="bookTicketsModal">
          {{ $t("general.seizeTheMoment") }}
        </h3>
      </template>

      <h4 class="text-center text-black-50">
        <i class="fa fa-calendar-day mb-2" /> {{ eventStartDateTime }}
        <span v-if="!eventIsSingleDay"> - {{ eventEndDateTime }} </span>
        <h1 class="text-center">{{ item.name }}</h1>
      </h4>
      <!--Form and Ticket Content-->
      <template v-if="!loading">
        <div class="row mt-4">
          <div class="col-md-12">
            <div id="ticketing" class="text-center mt-2">
              <div v-if="getTickets().length === 0" class="text-center">
                <small class="text-black-50">
                  <i class="fas fa-ticket-alt" size="lg" />
                  {{ $t("bookButtonComponent.noAvailableTicketsAtTheMoment") }}
                </small>
              </div>

              <el-steps
                v-else
                :active="activeStep"
                finish-status="success"
                class="mb-4"
                align-center
              >
                <el-step
                  :title="$t('general.tickets')"
                  icon="el-icon-s-ticket"
                ></el-step>
                <el-step
                  :title="$t('general.registration')"
                  icon="el-icon-s-order"
                ></el-step>
                <el-step
                  :title="$t('general.bookMeIn')"
                  icon="el-icon-s-claim"
                ></el-step>
              </el-steps>

              <template v-if="activeStep === 0">
                <div
                  v-if="getTickets().length > 0"
                  v-for="ticket in getTickets()"
                  :key="ticket.id"
                  class="ml-2"
                >
                  <el-collapse accordion>
                    <el-collapse-item :name="ticket.id">
                      <template slot="title">
                        <div class="d-flex w-100 justify-content-between">
                          <div>
                            <div class="d-flex w-100 align-items-center">
                              <h4 class="mb-1" style="margin-top: 0.75rem">
                                <i class="fas fa-ticket-alt mr-1" />
                                {{ ticket.name }}
                              </h4>
                            </div>
                          </div>
                          <small
                            v-if="
                              ticket.metadata.final_price > 0 ||
                              ticket.metadata.price > 0
                            "
                            class="text-black-60"
                          >
                            {{ $t("bookButtonComponent.price") }}: $
                            {{
                              ticket.metadata.final_price ||
                              ticket.metadata.price
                            }}
                          </small>
                          <small v-else class="text-black-60"> RSVP </small>
                        </div>
                      </template>

                      <div class="row align-content-around mt-2">
                        <div class="col-md-5 text-left ml-3 mt-2">
                          <h4>{{ $t("general.description") }}</h4>
                          <p class="text-sm ml-1 mb-2 mt-3">
                            <span class="text-black-50">
                              {{
                                ticket.metadata.custom_description ||
                                ticket.description
                              }}
                            </span>
                          </p>
                        </div>

                        <div class="col-md-3 text-left ml-3 mt-2">
                          <h4>{{ $t("bookButtonComponent.price") }}</h4>
                          <p class="text-sm ml-1 mb-1 mt-3">
                            <span
                              v-if="
                                ticket.metadata.final_price > 0 ||
                                ticket.metadata.price > 0
                              "
                              class="text-black-50"
                            >
                              $
                              {{
                                ticket.metadata.final_price ||
                                ticket.metadata.price
                              }}
                            </span>

                            <span v-else class="text-black-50">
                              {{ $t("bookButtonComponent.free") }}
                            </span>
                          </p>
                        </div>

                        <div class="col-md-3 text-left mt-2">
                          <div
                            class="form-group ticket-quantity mr-4"
                            v-if="ticketIsAvailable(ticket)"
                          >
                            <label class="form-control-label ml-3">
                              <h4>{{ $t("bookButtonComponent.quantity") }}</h4>
                            </label>

                            <div class="text-center ml-4 mt--3">
                              <el-input-number
                                max-width="50px"
                                placeholder="0"
                                v-model="ticket.selectedQuantity"
                                @change="
                                  setTicketToAttendee(
                                    ticket,
                                    getTransactionId()
                                  )
                                "
                                :min="0"
                                :max="
                                  ticket.metadata.max_quantity_per_person ||
                                  (ticket.metadata.total_quantity > -1
                                    ? ticket.metadata.total_quantity
                                    : undefined)
                                "
                                :disabled="disableAddTicketButton(ticket)"
                              >
                              </el-input-number>
                            </div>
                          </div>
                          <div
                            class="form-group ticket-quantity mr-4"
                            v-else-if="ticketIsSoldOut(ticket)"
                          >
                            <label class="form-control-label ml-3">
                              <h4>
                                {{ $t("bookButtonComponent.availability") }}
                              </h4>
                            </label>
                            <div class="ml-4 mt--3">
                              {{ $t("bookButtonComponent.ticketIsSoldOut") }}
                            </div>
                          </div>
                          <div
                            class="form-group ticket-quantity mr-4"
                            v-else-if="ticketIsNotAvailableAnyMore(ticket)"
                          >
                            <label class="form-control-label ml-3">
                              <h4>
                                {{ $t("bookButtonComponent.availability") }}
                              </h4>
                            </label>
                            <div class="ml-4 mt--3">
                              <badge
                                :type="ticketAvailability(ticket).label"
                                class="pl-3 pr-3"
                                >{{ $t("bookButtonComponent.soldOut") }}
                              </badge>
                            </div>
                          </div>
                          <div
                            class="form-group ticket-quantity mr-4"
                            v-else-if="ticketIsNotAvailableYet(ticket)"
                          >
                            <label class="form-control-label ml-3">
                              <h4>
                                {{ $t("bookButtonComponent.availability") }}
                              </h4>
                            </label>
                            <div class="ml-4 mt--3">
                              <badge
                                :type="ticketAvailability(ticket).label"
                                class="pl-3 pr-3"
                              >
                                <span class="status"
                                  >{{ ticketAvailability(ticket).message }}
                                </span>
                              </badge>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12" v-if="hideEndOfSalesDate">
                        <small class="text-xs text-muted"
                          >{{ $t("bookButtonComponent.availableUntil") }}:
                        </small>
                        <slot name="label">
                          <badge rounded type="primary" size="lg">
                            <strong class="text-black-40">
                              {{ getAvailability(ticket.metadata.end_sale_at) }}
                            </strong>
                          </badge>
                        </slot>
                        <div
                          class="progress-label mb-3"
                          v-if="ticket && ticket.metadata.total_quantity < 0"
                        ></div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!--Attendees Name & Email-->
        <template v-if="activeStep === 1">
          <div
            v-for="(attendeeTicket, index) in attendee.selectedTickets"
            :key="index"
          >
            <div class="pt-5 pb-2">
              <base-alert type="secondary" class="text-wrap">
                <div class="text-left">
                  <b>{{ attendeeTicket.quantity }} x </b>
                  <i class="fas fa-ticket-alt mr-2" />
                  <b>{{ attendeeTicket.ticket_name }}</b>
                </div>
              </base-alert>
            </div>
            <div class="row mb--4" v-if="attendeeTicket.ticket_id">
              <hr style="max-width: 60%" />
              <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <base-input
                  :label="$t('bookButtonComponent.fullName')"
                  type="text"
                  :placeholder="
                    $t('bookButtonComponent.enterTheCorrectFullName')
                  "
                  v-model="attendeeTicket.name"
                  name="Full Name"
                  required
                >
                </base-input>
              </div>
              <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <base-input
                  label="Email *"
                  type="email"
                  :placeholder="
                    $t(
                      'bookButtonComponent.provideValidEmailToReceiveYourTicket'
                    )
                  "
                  v-model="attendeeTicket.email"
                  name="Email"
                  required
                >
                </base-input>
              </div>
              <div class="col-12 col-md-12 col-sm-12">
                <base-input
                  :label="
                    hasSpecialOrDietaryRequirements()
                      ? $t('bookButtonComponent.dietaryOrSpecialRequirements')
                      : 'Special or Other requirements'
                  "
                >
                  <textarea
                    class="form-control"
                    :placeholder="
                      $t(
                        'bookButtonComponent.provideYourSpecialOrDietaryRequirements'
                      )
                    "
                    v-model="attendeeTicket.special_requirements"
                    :name="$t('bookButtonComponent.dietaryRequirements')"
                    rows="3"
                    max-length="400"
                  />
                </base-input>
              </div>
              <div
                v-if="attendeeTicket.phone_number_enabled"
                class="col-12 col-md-12 col-sm-12"
              >
                <slot name="label">
                  <label class="form-control-label"> Phone number * </label>
                </slot>
                <VuePhoneNumberInput
                  v-model="attendeeTicket.phone_number_only"
                  :required="true"
                  color="#5e72e4"
                  @update="getPhoneNumber($event, attendeeTicket)"
                  valid-color="#5e72e4"
                  error-color="#e8aa53"
                  default-country-code="AU"
                  border-radius="8.8"
                />
                <div
                  v-if="
                    attendeeTicket.phone_number?.length > 0 &&
                    !attendeeTicket.phone_number_valid
                  "
                  class="invalid-feedback text-wrap"
                  style="display: block"
                >
                  Please provide a valid phone number
                </div>
              </div>
            </div>
          </div>
        </template>

        <hr v-if="getTickets().length === 0" style="max-width: 60%" />

        <div class="row justify-content-center">
          <div
            class="col-auto mt-2 mb-3"
            v-if="activeStep === 0 && attendee.selectedTickets.length"
          >
            <small class="text-muted link-add-tag">
              <badge type="base" tag="a" href="#" size="md">
                <a size="md" @click="resetTicketSelection()">
                  <i class="fa fa-eraser mr-2" />
                  {{ $t("bookButtonComponent.clearTicketSelection") }}
                </a>
              </badge>
            </small>
          </div>

          <!-- T&C -->
          <div
            v-if="activeStep === 0 && attendee.selectedTickets.length"
            class="mt-2"
          >
            <div class="col-md-12">
              <base-alert type="secondary">
                <div class="col">
                  <!-- Card body -->
                  <div class="row align-items-center">
                    <div class="col-auto ml--3">
                      <!-- T&C Checkbox -->
                      <base-checkbox
                        v-model="agreeCheckbox"
                        class="col-auto mb-3"
                      ></base-checkbox>
                    </div>

                    <!-- T&C Text -->
                    <div class="col ml--4">
                      <small id="event-ticket-registration" class="text-muted">
                        {{
                          $t(
                            "bookButtonComponent.byRegisteringForThisEventIconsentToThe"
                          )
                        }}
                        <a
                          href="https://www.bookmeinscotty.com/terms-of-use/"
                          target="_blank"
                          >{{ $t("general.termsOfUse") }}</a
                        >
                        {{ $t("general.and") }}
                        <a
                          href="https://www.bookmeinscotty.com/terms-and-conditions/"
                          target="_blank"
                          >{{ $t("general.termsAndConditions") }}</a
                        >
                        {{
                          $t(
                            "bookButtonComponent.ofThePlatformAndToOraganizersTerms"
                          )
                        }}
                      </small>
                    </div>

                  </div>
                </div>
              </base-alert>
            </div>
          </div>
        </div>
      </template>

      <!-- Ticket Booking Button -->
      <template slot="footer" v-if="!loading">
        <!-- RSVP/Free Tickets -->
        <base-button
          v-if="hasSelectedFreeTicket() && activeStep === 1"
          block
          type="danger"
          :disabled="validateCompleteButton()"
          @click="ticketModal(attendee, true)"
        >
          <span class="mr--2 text-center mt-0">{{
            attendee.selectedTickets.length
              ? attendee.selectedTickets.length + "x"
              : ""
          }}</span
          ><i class="fas fa-ticket-alt mr-1" />
          {{ $t("bookButtonComponent.completeRegistration") }}
        </base-button>

        <!-- Paid Tickets -->
        <base-button
          v-if="!hasSelectedFreeTicket() && activeStep === 1"
          block
          type="danger"
          :disabled="validateCompleteButton(attendee)"
          @click="customSnipcartAdding(attendee)"
        >
          <span class="mr--2 text-center mt-0">{{
            attendee.selectedTickets.length
              ? attendee.selectedTickets.length + "x"
              : ""
          }}</span
          ><i class="fas fa-ticket-alt mr-1" />
          {{ $t("bookButtonComponent.completeRegistration") }}
        </base-button>

        <base-button
          block
          type="danger"
          :disabled="attendee?.selectedTickets?.length === 0 || !agreeCheckbox"
          v-if="activeStep === 0"
          @click="nextStep()"
        >
          <span class="mr--2 text-center mt-0">{{
            attendee.selectedTickets.length
              ? attendee.selectedTickets.length + "x"
              : ""
          }}</span
          ><i class="fas fa-ticket-alt mr-1" />
          {{ $t("bookButtonComponent.attendanceDetails") }}
        </base-button>

        <!--Ticketing Details-->
        <div class="row col-md-12">
          <div v-if="activeStep === 1" class="col ml--4">
            <!-- Step Back  -->

            <badge type="base" tag="a" href="#" size="md">
              <a size="md" @click="backStep()">
                <slot name="icon"
                  ><i class="el-icon-d-arrow-left"> {{ $t("general.back") }}</i>
                </slot>
              </a>
            </badge>
          </div>
          <div v-else class="col ml--4">
            <!-- Back  -->
            <small class="text-muted text-center">
              <badge
                type="base"
                tag="a"
                href="https://www.bookmeinscotty.com/feedback-form/"
                target="_blank"
                size="md"
              >
                <slot name="icon"
                  ><i class="el-icon-help" />
                  {{ $t("general.needHelp") }}
                </slot>
              </badge>
            </small>
          </div>
          <div v-if="getTickets().length !== 0" class="col-auto mr--4">
            <small class="text-small text-muted text-center">
              <el-tooltip
                class="item"
                effect="light"
                :content="
                  item.refund_policy === null
                    ? $t(
                        'bookButtonComponent.organizersTermsAndConditionsAreNot'
                      )
                    : $t(
                        'bookButtonComponent.organizersTermsAndConditionsAreAvailable'
                      )
                "
                placement="top"
              >
                <i class="fa fa-info-circle" />
                <badge
                  v-if="item.refund_policy === null"
                  type="base"
                  tag="a"
                  size="md"
                >
                  {{ $t("general.termsAndConditions") }}</badge
                >
                <badge v-else type="success" tag="b" size="md">
                  {{ $t("general.organisersTermsAndConditions") }}
                </badge>
              </el-tooltip>
            </small>
          </div>
        </div>

        <!--FAQs Details-->
        <div class="col-md-12" v-if="item.refund_policy !== null">
          <el-collapse accordion>
            <el-collapse-item title="Event-Specific Terms & Policies" name="1">
              <div v-html="item.refund_policy"></div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </template>

      <loading-panel v-if="loading"></loading-panel>
    </modal>
    <!-- ./Modal Content-->
  </div>
</template>

<script>
import {
  Button,
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Tooltip,
  Skeleton,
  SkeletonItem,
  Collapse,
  CollapseItem,
  InputNumber,
  Steps,
  Step,
  Tabs,
  TabPane,
} from "element-ui";
import Badge from "@/components/Badge";
import { TimeLine, TimeLineItem, LoadingPanel } from "@/components";
import "sweetalert2/dist/sweetalert2.css";
import BaseSwitchVue from "../BaseSwitch.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { v4 as uuidv4 } from "uuid";
const moment = require("moment-timezone");

export default {
  name: "BookButtonComponent",
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [InputNumber.name]: InputNumber,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [TimeLine.name]: TimeLine,
    [TimeLineItem.name]: TimeLineItem,
    [BaseSwitchVue]: BaseSwitchVue,
    LoadingPanel,
    Badge,
    VuePhoneNumberInput,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    modals: {
      type: Object,
      default: null,
    },
    onlyBookButton: {
      type: Boolean,
      default: false,
    },
    hideEndOfSalesDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attendee: {
        name: "",
        email: "",
        special_requirements: this.hasSpecialOrDietaryRequirements()
          ? ""
          : undefined,
        phone_number: "",
        selectedTickets: [],
        type: "attendees",
      },
      tags: [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      activeStep: 0,
      agreeCheckbox: true,
      loading: false,
      emailRegex:
        /^(?=.{1,320}$)(?!.*\.{2,})(?!.*[.@]{2,})(?!^[.@]|[@_-]$)([a-zA-Z0-9._-]{1,64})@(?=.{1,255}$)([a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-_]+)*\.[a-zA-Z0-9-_]{2,})$/,
    };
  },
  watch: {
    modals: {
      handler: function (val) {
        if (val?.openBooking === "reset") {
          this.openModal();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.items = {
      item: this.item,
      tags: this.tags,
      dates: [],
    };
    this.setSnipcart(this.item);
  },

  beforeDestroy() {
    document.removeEventListener(
      "SnipcartCompleted",
      this.SnipcartCompletedFunc
    );
    document.removeEventListener("SnipcartLoading", this.snipcartLoadingFunc);
    document.removeEventListener(
      "SnipcartStopLoading",
      this.snipcartStopLoadingFunc
    );

    Snipcart.unsubscribe("order.completed");
    Snipcart.unsubscribe("cart.closed");
    Snipcart.unsubscribe("item.adding");
    Snipcart.unsubscribe("page.validating");
  },

  computed: {
    eventIsSingleDay() {
      let start = moment(this.items.item.start_at);
      let end = moment(this.items.item.end_at);

      return start.isSame(end, "day");
    },
    eventCurrency() {
      return this.items.item.currency ?? this.getUserCurrency();
    },
    eventStartDateTime() {
      return moment(this.items.item.start_at).format("ddd, DD MMM YY");
    },
    eventEndDateTime() {
      return moment(this.items.item.end_at).format("ddd, DD MMM YY");
    },
    localStartTime() {
      const originalTime = moment.tz(
        this.items.item.start_at,
        this.items.item.timezone
      );
      return originalTime.clone().tz(this.timezone).format("LT");
    },
    localEndTime() {
      const originalTime = moment.tz(
        this.items.item.end_at,
        this.items.item.timezone
      );
      return originalTime.clone().tz(this.timezone).format("LT");
    },
    apiEndpoint() {
      return process.env.VUE_APP_API_BASE_URL;
    },
  },

  methods: {
    validateCompleteButton() {
      let isInvalid = false;
      this.attendee.selectedTickets.forEach((item) => {
        if (
          item?.name?.length === 0 ||
          !this.emailRegex.test(item?.email) ||
          (item?.phone_number_enabled &&
            (!item?.phone_number || !item?.phone_number_valid))
        ) {
          isInvalid = true;
        }
      });
      return isInvalid;
    },
    SnipcartCompletedFunc(e) {
      this.ticketModal(e.detail);
    },
    snipcartLoadingFunc() {
      this.loading = true;
    },
    snipcartStopLoadingFunc() {
      this.loading = false;
    },
    setSnipcart() {
      document.addEventListener("SnipcartCompleted",
        this.SnipcartCompletedFunc,
        false
      );
      document.addEventListener("SnipcartLoading",
        this.snipcartLoadingFunc,
        false
      );
      document.addEventListener("SnipcartStopLoading",
        this.snipcartStopLoadingFunc,
        false
      );

      Snipcart.subscribe("page.validating", function (ev, data) {
        if (ev.type === "cart-content") {
          data.forEach((item) => {
            const emailRegex =
              /^(?=.{1,320}$)(?!.*\.{2,})(?!.*[.@]{2,})(?!^[.@]|[@_-]$)([a-zA-Z0-9._-]{1,64})@(?=.{1,255}$)([a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-_]+)*\.[a-zA-Z0-9-_]{2,})$/;
            for (let i = 0; i < item.customFields.length; i++) {
              if (
                item.customFields[i].name !== "Dietary Requirements" &&
                item.customFields[i].name !== "Selected Dietary Requirements" &&
                item.customFields[i].name !==
                  "Special or Dietary Requirements" &&
                item.customFields[i].value === ""
              ) {
                ev.addError(item.id, "All fields are required");
              } else if (
                item.customFields[i].name === "Email" &&
                !emailRegex.test(item.customFields[i].value)
              ) {
                ev.addError(item.id, "Please enter a valid email");
              }
            }
          });
        }
      });

      Snipcart.subscribe("cart.closed", function () {
        Snipcart.api.items.clear().then(function () {});
      });

      Snipcart.subscribe("order.completed", function (data) {
        const guests = [];

        data?.items.forEach((item) => {
          let name;
          let email;
          let special_requirements;
          let phone_number;
          item?.customFields.forEach((field) => {
            if (field.name === "Full Name") {
              name = field.value;
            } else if (field.name === "Email") {
              email = field.value;
            } else if (field.name === "Dietary Requirements"
                || field.name === "Selected Dietary Requirements"
                || field.name === "Special or Dietary Requirements") {
              special_requirements = field.value;
            } else if (field.name === "Phone number") {
              phone_number = field.value;
            }
          });

          guests.push({
            email: email,
            name: name,
            special_requirements: special_requirements,
            phone_number: phone_number,
            item_id: item?.metadata,
            price: item.final_price || item.price,
            ticket_id: item.id,
            quantity: item.quantity,
            is_paid: 1,
            snipcart_id: item.uniqueId ?? null,
            snipcart: item ?? null,
            transaction_id: item.uniqueId ?? null,
            invoice_number: data.invoiceNumber,
            snipcart_payment_status: data.status,
          });
        });

        const triggerCompletedEvent = new CustomEvent("SnipcartCompleted", {
          detail: { guests },
        });
        // Trigger Payment events
        document.dispatchEvent(triggerCompletedEvent);
      });

      // ToDo - Here is to be set for custom currency (multi-currency)
      Snipcart.api.cart.currency(this.eventCurrency);
    },
    customSnipcartAdding(attendee) {
      const evtLoading = new CustomEvent("SnipcartLoading");

      // Trigger Payment events
      document.dispatchEvent(evtLoading);

      Snipcart.api.configure("show_cart_automatically", false);

      let arr = [];
      for (let i = 0; i < attendee.selectedTickets.length; i++) {
        let temp = {};

        temp.id           = parseFloat(attendee.selectedTickets[i].ticket_id);
        temp.description  = this.item.name
        temp.name         = attendee.selectedTickets[i].ticket_name;

        temp.url =
          this.apiEndpoint +
          "/public/tickets/" +
          parseFloat(attendee.selectedTickets[i].ticket_id) +
          "/items/" +
          parseFloat(attendee.selectedTickets[i].item_id) +
          "/qty/" +
          parseFloat(attendee.selectedTickets[i].quantity);

        temp.price =
          attendee.selectedTickets[i].final_price ||
          attendee.selectedTickets[i].price;

        temp.price = parseFloat(temp.price);

        temp.customFields = [
          {
            name: "Full Name",
            id: "full-name",
            value: attendee.selectedTickets[i].name,
            // required: attendee.selectedTickets[i].personalized_ticket === 1,
          },
          {
            name: "Email",
            id: "full-email",
            value: attendee.selectedTickets[i].email,
            // required: attendee.selectedTickets[i].personalized_ticket === 1,
          },

          {
            name: this.hasSpecialOrDietaryRequirements()
              ? // when changing this, also change the if statement at snipcart 'page.validating' event
                "Special or Dietary Requirements"
              : undefined,
            id: this.hasSpecialOrDietaryRequirements()
              ? "special-requirements"
              : undefined,
            value: this.hasSpecialOrDietaryRequirements()
              ? attendee.selectedTickets[i].special_requirements
              : undefined,
            type: this.hasSpecialOrDietaryRequirements()
              ? "textarea"
              : undefined,
          },
        ];

        if (attendee.selectedTickets[i].phone_number_enabled) {
          temp.customFields.push({
            name: "Phone number",
            id: "phone_number",
            value: attendee.selectedTickets[i].phone_number,
          });
        }

        temp.shippable = false;
        temp.metadata = parseFloat(attendee.selectedTickets[i].item_id);

        // temp.metadata =  formData.fields.reduce((acc, field) => {
        //   return { ...acc, itemId: field.label };
        // }, {})
        //   itemId: parseFloat(attendee.selectedTickets[i].item_id),
        //   ticketId: attendee.selectedTickets[i].ticket_id,
        //   ticketName: attendee.selectedTickets[i].ticket_name
        // }

        temp.stackable = false;
        temp.quantity = parseFloat(attendee.selectedTickets[i].quantity);

        arr.push(temp);
      }

      Snipcart.api.items.add(arr).then(function () {
        Snipcart.api.configure("show_cart_automatically", true);

        const evtStopLoading = new CustomEvent("SnipcartStopLoading");
        // Trigger Payment events
        document.dispatchEvent(evtStopLoading);

        Snipcart.api.modal.show();
      });
    },
    ticketModal(attendee, freeTicket) {
      this.loading = true;

      if (freeTicket) {
        attendee.selectedTickets.forEach((ticket) => {
          // remove extra attributes
          delete ticket.type;
          delete ticket.personalized_ticket;

          ticket.is_paid = 1;
        });

        attendee = {
          guests: attendee.selectedTickets,
        };
      } else {
        setTimeout(() => {
          Snipcart.api.modal.close();
        }, "1000");
      }
      this.$emit("addTicket", attendee);
    },
    getTransactionId() {
      return uuidv4();
    },
    setTicketToAttendee(ticket, transactionId) {
      let temp;
      // Dev Tip - Uncomment following to test phone number functionality
      // ticket.metadata.phone_number_enabled = 1;
      if (ticket.selectedQuantity) {
        temp = {
          item_id: this.item.id,
          transaction_id: transactionId,
          ticket_id: ticket.id,
          price: ticket.metadata.price,
          final_price: ticket.metadata.final_price,
          ticket_name: ticket.name,
          quantity: ticket.selectedQuantity,
          personalized_ticket: Boolean(ticket.metadata.personalized_ticket),
          phone_number_enabled: Boolean(ticket.metadata.phone_number_enabled),
        };

        // Personalized-Individual Ticketing
        if (ticket.metadata.personalized_ticket === 1) {
          delete temp.quantity;

          this.attendee.selectedTickets = this.attendee.selectedTickets.filter(
            (item) => item.ticket_id !== ticket.id
          );

          for (let j = 0; j < ticket.selectedQuantity; j++) {
            this.attendee.selectedTickets.push({
              ...temp,
              quantity: 1,
              transaction_id: uuidv4(),
            });
          }
          // Group Ticketing
        } else {
          this.attendee.selectedTickets = this.attendee.selectedTickets.some(
            (item) => item.ticket_id === temp.ticket_id
          )
            ? this.attendee.selectedTickets.map((item) =>
                item.ticket_id === temp.ticket_id ? temp : item
              )
            : [...this.attendee.selectedTickets, temp];
        }
        // ToDo - Which use-case is this?
      } else {
        this.attendee.selectedTickets = this.attendee.selectedTickets.filter(
          (item) => item.ticket_id !== ticket.id
        );
      }
    },
    hasSelectedFreeTicket() {
      return this.attendee.selectedTickets.some(
        (item) => item.final_price === 0 || item.price === 0
      );
    },
    disableAddTicketButton(ticket) {
      return (
        this.attendee.selectedTickets.length > 0 &&
        ((ticket?.metadata?.final_price !== 0 &&
          ticket?.metadada?.price !== 0 &&
          this.hasSelectedFreeTicket()) ||
          ((ticket?.metadata?.final_price === 0 ||
            ticket?.metadada?.price == 0) &&
            !this.hasSelectedFreeTicket()))
      );
    },
    getTickets() {
      // we used to return only available tickets
      // let availableTickets = [];
      // this.item?.event_tickets.forEach((ticket) => {
      //   if (
      //     moment().isBetween(
      //       ticket.metadata.start_sale_at,
      //       ticket.metadata.end_sale_at,
      //       "day",
      //       "[]"
      //     ) &&
      //     (ticket?.metadata?.sold_quantity <=
      //       ticket?.metadata?.total_quantity ||
      //       ticket?.metadata?.total_quantity === -1)
      //   ) {
      //     availableTickets.push(ticket);
      //   }
      // });
      // return availableTickets;
      return this.item?.event_tickets || [];
    },
    ticketIsAvailable(ticket) {
      return (
        moment().isBetween(
          ticket.metadata.start_sale_at,
          ticket.metadata.end_sale_at,
          "day",
          "[]"
        ) &&
        (ticket?.metadata?.sold_quantity <= ticket?.metadata?.total_quantity ||
          ticket?.metadata?.total_quantity === -1)
      );
    },
    ticketAvailability(eventDate) {
      // End-User DateΤZ
      const userTimezone = this.timezone;
      const today = moment().tz(userTimezone);

      // Event DateTZ
      const eventTimezone = moment.tz(
        eventDate.metadata.start_sale_at,
        this.items.item.timezone
      );
      const happening = eventTimezone.clone().tz(userTimezone);

      return {
        message: happening.fromNow(),
        label: today.diff(happening, "hours") < 0 ? "success" : "warning",
      };
    },
    ticketIsSoldOut(ticket) {
      return !(
        ticket?.metadata?.sold_quantity <= ticket?.metadata?.total_quantity ||
        ticket?.metadata?.total_quantity === -1
      );
    },
    ticketIsNotAvailableAnyMore(ticket) {
      return moment().isAfter(
        ticket.metadata.start_sale_at,
        ticket.metadata.end_sale_at,
        "day",
        "[]"
      );
    },
    ticketIsNotAvailableYet(ticket) {
      return moment().isBefore(
        ticket.metadata.start_sale_at,
        ticket.metadata.end_sale_at,
        "day",
        "[]"
      );
    },
    getAvailability(available) {
      return moment(available).format("Do MMM YYYY, h:mm:ss a");
    },
    openModal() {
      // Send action to Google Analytics
      try {
        this.$gtag.event("bmis_cta_open_ticket_modal", {
          eventId: this.item.id,
        });
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: error,
        });
      }

      // reset step to 0
      this.activeStep = 0;

      // reset selectedQuantity to 0
      this.resetTicketSelection();
      this.loading = false;
      this.modals.openBooking = true;
    },
    resetTicketSelection() {
      this.getTickets().forEach((ticket) => {
        ticket.selectedQuantity = 0;
      });
      this.attendee = {
        name: "",
        email: "",
        special_requirements: this.hasSpecialOrDietaryRequirements()
          ? ""
          : undefined,
        phone_number: "",
        phone_number_only: undefined,
        phone_number_valid: undefined,
        selectedTickets: [],
        type: "attendees",
      };
    },
    nextStep() {
      if (this.activeStep++ > 2) this.activeStep = 0;
    },
    backStep() {
      if (this.activeStep++ == 1) this.activeStep = 0;
    },
    hasSpecialRequirements() {
      return true;
    },
    hasSpecialOrDietaryRequirements() {
      return this.hasSpecialRequirements();
    },
    getCustomBookColor() {
      if (this.item.custom_book_button_label) {
        return "";
      } else {
        return "bg-gradient-danger";
      }
    },
    getPhoneNumber(numberEvent, attendee) {
      attendee.phone_number_valid = numberEvent?.isValid;
      attendee.phone_number =
        numberEvent?.formattedNumber || numberEvent?.phoneNumber;
    },
  },
};
</script>

<style scoped>
#base-cta-ticket-booking {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-radius: 24px 24px 0 24px !important;
}
</style>
