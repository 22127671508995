<template>
  <div class="container-fluid mt-3">
    <!-- Back  -->
    <back-button @click="goBack" />
    <!-- Form  -->
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">
        <transition name="el-zoom-in-center">
          <div class="row justify-content-center mt-3">
            <div class="col-xl-6 order-xl-1">
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-md-8">
                      <h3 class="mb-0">
                        {{ $t("editSpeakerItem.stetUpTheProfileOfTheSpeaker") }}
                      </h3>
                    </div>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <base-input
                          :label="$t('editSpeakerItem.firstName') + '*'"
                          name="First Name"
                          type="text"
                          :placeholder="
                            $t('editSpeakerItem.firstNameOfTheSpeaker')
                          "
                          v-model="speaker.first_name"
                          required
                        />
                        <validation-error
                          :errors="apiValidationErrors.first_name"
                        />
                      </div>
                      <div class="col-md-6">
                        <base-input
                          :label="$t('editSpeakerItem.lastName') + '*'"
                          name="Last Name"
                          type="text"
                          :placeholder="
                            $t('editSpeakerItem.lastNameOfTheSpeaker')
                          "
                          v-model="speaker.last_name"
                          required
                        />
                        <validation-error
                          :errors="apiValidationErrors.last_name"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <base-input
                          :label="$t('editSpeakerItem.city') + '*'"
                          name="City"
                          type="text"
                          :placeholder="$t('editSpeakerItem.cityOfTheSpeaker')"
                          v-model="speaker.city"
                          required
                        />
                        <validation-error :errors="apiValidationErrors.city" />
                      </div>
                      <div class="col-md-6">
                        <base-input
                          :label="$t('editSpeakerItem.country')"
                          required
                        >
                          <el-select
                            v-model="speaker.country"
                            filterable
                            prepend-icon="fas fa-user"
                            :placeholder="$t('general.select') + '...'"
                          >
                            <el-option
                              v-for="country in getCountriesList()"
                              :key="country"
                              :label="country"
                              :value="country"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                    <base-input
                      type="text"
                      :label="$t('editSpeakerItem.expertCredentials') + '*'"
                      success-message="Summarise youe expertise on the subjects you'll likely be talking about. Unlike bio, this should be short and to the point'"
                    >
                      <textarea
                        v-model="speaker.expertise"
                        :placeholder="
                          $t(
                            'editSpeakerItem.summarizeYoueExpertiseOnTheSubjectYoullLikely'
                          ) + '.'
                        "
                        class="form-control"
                        rows="3"
                        name="Expertise"
                        required
                      >
                      </textarea>
                    </base-input>
                    <validation-error :errors="apiValidationErrors.expertise" />
                    <base-input
                      type="text"
                      :label="$t('editSpeakerItem.bio') + '*'"
                      success-message="With bio you can go into a bit more detail about your background."
                    >
                      <textarea
                        v-model="speaker.biography"
                        :placeholder="
                          $t(
                            'editSpeakerItem.withBioYouCanGoIntoBitMoreDetailAboutYourBackground'
                          ) + '.'
                        "
                        class="form-control"
                        rows="3"
                        name="Biography"
                        required
                      >
                      </textarea>
                    </base-input>
                    <validation-error :errors="apiValidationErrors.biography" />
                  </div>
                </card>
            </div>
            <div class="col-xl-6 order-xl-1">
              <card header-classes="bg-transparent" name="details">
                <div slot="header" class="row align-items-center">
                  <div class="col-md-8">
                    <h3 class="mb-0">
                      {{ $t("editSpeakerItem.provideMoreDetails") }}
                    </h3>
                  </div>
                </div>
                <div class="card-body">
                  <base-input :label="$t('editSpeakerItem.timeZone')" required>
                    <el-select
                      v-model="speaker.timezone"
                      filterable
                      prepend-icon="fas fa-user"
                      placeholder="$t('general.select')+'...'"
                    >
                      <el-option
                        v-for="speaker in allTimezones"
                        :key="speaker"
                        :label="speaker"
                        :value="speaker"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input :label="$t('editSpeakerItem.availableFor')">
                    <base-switch
                      class="mr-1"
                      v-model="speaker.virtual"
                      :description="{
                        show: true,
                        content: $t('editSpeakerItem.virtualEvents'),
                      }"
                    ></base-switch>
                    <base-switch
                      class="mr-1"
                      v-model="speaker.physical"
                      :description="{
                        show: true,
                        content: $t('editSpeakerItem.inPersonEvents'),
                      }"
                    ></base-switch>
                    <base-switch
                      class="mr-1"
                      v-model="speaker.podcast"
                      :description="{
                        show: true,
                        content: $t('editSpeakerItem.podcastInterviews'),
                      }"
                    ></base-switch>
                  </base-input>

                  <base-input :label="$t('editSpeakerItem.categories')">
                    <el-select
                      v-model="speaker.speaker_categories"
                      multiple
                      :placeholder="$t('general.select') + '...'"
                    >
                      <el-option
                        v-for="single_category in allCategories"
                        :key="single_category.id"
                        :value="single_category.id"
                        :label="single_category.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>

                  <!-- Social Profiles -->
                  <base-input :label="$t('editSpeakerItem.contacts')">
                    <el-tabs
                      v-model="dynamicTabs.editableTabsValue"
                      id="dynamic-tabs"
                      type="card"
                      :stretch="true"
                      editable
                      @edit="handleDynamicTabs"
                    >
                      <el-tab-pane
                        v-for="tab_ in dynamicTabs.editableTabs"
                        :key="tab_.sort"
                        :label="tab_.title"
                        :name="tab_.name"
                        :lazy="true"
                      >
                        <base-input
                          :label="$t('editSpeakerItem.contactType') + '*'"
                          required
                        >
                          <el-select
                            v-model="tab_.type"
                            filterable
                            prepend-icon="fas fa-user"
                            :placeholder="$t('general.select') + '...'"
                          >
                            <el-option
                              v-for="contactType in contactTypes"
                              :key="contactType"
                              :label="contactType"
                              :value="contactType"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                        <base-input
                          :label="$t('editSpeakerItem.enterContact') + '*'"
                          v-model="tab_.value"
                          name="Contact value"
                          required
                        />
                      </el-tab-pane>
                    </el-tabs>
                  </base-input>

                  <template>
                    <label class="form-control-label">
                      {{ $t("editSpeakerItem.profileImage") }}</label
                    >

                    <div
                      class="card bg-dark text-white border-0"
                      v-show="!actions.uploadProfile"
                    >
                      <img
                        class="card-img"
                        :src="profileImage"
                        alt="Speaker Profile Image"
                      />
                      <div class="card-img-overlay d-flex align-items-center">
                        <div>
                          <base-button
                            type="primary"
                            @click="actions.uploadProfile = true"
                          >
                            <i class="fa fa-upload mr-2" />{{
                              $t("editSpeakerItem.uploadNewImage")
                            }}</base-button
                          >
                        </div>
                      </div>
                    </div>

                    <base-file-input
                      :help-message="
                        '<i class=\'fa fa-upload\'></i>' +
                        $t('editSpeakerItem.selectYourBestProfileToUpload') +
                        '</br> <small class=\'filepond--label-action text-muted\'>' +
                        $t('editSpeakerItem.dropOrBrowseFiles') +
                        '</small></br>'
                      "
                      :endpoint="uploadAssetUrl('profile', speaker.profile_image)"
                      preview-height="400"
                      v-show="actions.uploadProfile"
                    />
                  </template>
                </div>
              </card>

              <!-- Sticky buttons -->
              <div id="sticky-buttons" class="my-4 text-right">
                <card type="frame">
                  <div class="mt--3 mb--3">
                    <base-button
                      type="button"
                      class="btn btn-primary"
                      v-if="!loading"
                      data-testid="btn-create-item"
                      native-type="submit"
                    >
                      <i class="fa fa-save" />
                      {{ $t("editSpeakerItem.updateSpeaker") }}
                    </base-button>
                    <div class="text-center" v-if="loading">
                      <slot name="loading">
                        <i class="fas fa-spinner fa-spin"></i>
                      </slot>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </transition>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import moment from "moment-timezone";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option, Tabs, TabPane } from "element-ui";
// components
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BackButton from "@/components/BackButton.vue";
import BaseFileInput from "@/components/Files/BaseFileInput";

export default {
  name: "edit-speakers",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    ValidationError,
    BaseInput,
    BackButton,
    BaseFileInput,
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      file: null,
      allCategories: [],
      allTimezones: [],
      speaker: {
        type: "speakers",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        country: "Australia",
        categories: [],
        speaker_categories: [],
      },
      actions: {
        uploadProfile: false,
      },
      dynamicTabs: {
        editableTabsValue: "",
        editableTabs: [],
        tabIndex: 1,
      },
      contactTypes: [
        "Email",
        "Phone",
        "Facebook",
        "Instagram",
        "LinkedIn",
        "Twitter",
      ],
    };
  },
  computed: {
    profileImage() {
      if (!_.isEmpty(this.speaker.profile_image)) {
        if (
          this.speaker.profile_image.includes("http://") ||
          this.speaker.profile_image.includes("https://")
        ) {
          return this.speaker.profile_image;
        }
        return process.env.VUE_APP_AWS_S3_URL + this.speaker.profile_image;
      }
      return (
        process.env.VUE_APP_BASE_URL +
        "/img/brand/bmis-default-asset-placeholder.png"
      );
    },
  },
  created() {
    this.auth();
    this.getCategories();
    this.allTimezones = moment.tz.names();
    this.getSpeaker();
  },
  methods: {
    async getSpeaker() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        let params = {
          filter: {
            ...(this.user
              ? { contractor_id: this.user.id }
              : { contractor_id: 0 }),
          },
          include: "categories",
        };
        await this.$store
          .dispatch("speakers/get", { id, params })
          .then(() => (this.speaker = this.$store.getters["speakers/speaker"]))
          .then(() => {
            if (this.speaker?.contacts?.length > 0) {
              this.speaker?.contacts?.forEach((contact, index) => {
                this.dynamicTabs.editableTabs.push({
                  title: this.$t("editSpeakerItem.newContact"),
                  type: contact.type,
                  name: (index + 1).toString(),
                  value: contact.value,
                });
              });
            } else {
              this.dynamicTabs.editableTabs = [
                {
                  title: this.$t("editSpeakerItem.newContact"),
                  type: "Email",
                  name: "1",
                  value: "",
                },
              ];
            }
            this.dynamicTabs.editableTabsValue = "1";
            this.speaker.speaker_categories = this.speaker?.categories?.map(
              (obj) => obj.id
            );
          });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
         message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
    customFocus() {
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar")[0].focus();
      }, 10);
    },
    async auth() {
      try {
        // Fetch User data
        // await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message:this.$t('editSpeakerItem.oopsSomethingWentWrongWithYourAccount')+'!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Speakers" });
    },
    redirect(speaker) {
      this.$router.push({
        name: "Speaker Preview",
        params: { id: speaker.id },
      });
    },
    async onSubmit(values) {
      this.loading = true;

      this.speaker.contacts = [];
      this.dynamicTabs.editableTabs.forEach((contact) => {
        if (contact.type && contact.value) {
          this.speaker.contacts.push({
            type: contact.type,
            value: contact.value,
          });
        }
      });
      try {
        await this.$store.dispatch("speakers/update", this.speaker);
        this.speaker = await this.$store.getters["speakers/speaker"];
        this.loading = false;
        this.$notify({
          type: "success",
          message: this.$t('editSpeakerItem.speakerUpdatedSuccessfully')+'.',
        });

        this.redirect(this.speaker);
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
        if (this.speaker.id) {
          await this.$store.dispatch("speakers/destroy", this.speaker.id);
        }
      }
    },
    handleDynamicTabs(tabName, action) {
      if (action === "add") {
        let newTabName = ++this.dynamicTabs.tabIndex + "";
        this.dynamicTabs.editableTabs.push({
          title: this.$t("editSpeakerItem.newContact"),
          name: newTabName,
          type: "Email",
          value: "",
        });
        this.dynamicTabs.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.dynamicTabs.editableTabs;
        let activeName = this.dynamicTabs.editableTabsValue;

        if (activeName === tabName) {
          tabs.forEach((tab, index) => {
            if (tab.name === tabName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        this.dynamicTabs.editableTabsValue = activeName;
        this.dynamicTabs.editableTabs = tabs.filter(
          (tab) => tab.name !== tabName
        );
      }
    },
    uploadAssetUrl(resource, previousImage = null) {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "/uploads/speakers/" +
        this.$route.params.id +
        "/" +
        resource +
        "?previousImg=" +
        previousImage
      );
    },
    async getCategories() {
      try {
        await this.$store.dispatch("categories/list");
        this.allCategories = this.$store.getters["categories/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>

<style></style>
