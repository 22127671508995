<template>
  <div class="row">
    <div class="col-md-6">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb-0">{{ $t("editEvents.admissionsAndAddOns") }}</p>
          </div>
        </div>

        <div class="card-body">
          <base-input :label="$t('editEvents.selectTicketsForThisEvent')">
            <el-select
              v-model="tickets"
              @change="setSelectedTickets()"
              multiple
              :placeholder="$t('general.select') + '...'"
            >
              <el-option
                v-for="single_ticket in all_tickets"
                :key="single_ticket.id"
                :value="single_ticket.id"
                :label="single_ticket.name"
              >
              </el-option>
            </el-select>
            <a @click="showAddTicketModal = true" class="link-add-tag">
              <small style="font-weight: bolder"
                >+ {{ $t("editEvents.newTicket") }}
              </small>
            </a>
          </base-input>
        </div>

        <div class="ml-4 mr-4">
          <base-alert type="secondary">
            <el-timeline class="ml--5 mt-2 mb--3">
              <el-timeline-item
                icon="fa fa-info-circle"
                :color="color.base"
                size="large"
              >
                <small class="text-muted">
                  <strong>Free tickets</strong> are always free-of-charge and
                  can be used as <strong>RSVP invitations</strong> or
                  <strong>Early Access Sales registration</strong> with any
                  event.
                </small>
              </el-timeline-item>
              <el-timeline-item
                icon="fa fa-info-circle"
                :color="color.base"
                size="large"
              >
                <small class="text-muted">
                  A <strong>ticket service fee</strong> of will apply when
                  selling tickets with price, you have the option to
                  <strong
                    >pass the fee on the end-customer or absorb the cost
                    yourself</strong
                  >. -
                  <a
                    href="https://www.bookmeinscotty.com/pricing/"
                    target="_blank"
                  >
                    [ Read More ]</a
                  >
                </small>
              </el-timeline-item>
              <el-timeline-item
                icon="fa fa-info-circle"
                :color="color.base"
                size="large"
              >
                <small class="text-muted">
                  {{ $t("editEvents.all") }}
                  <strong> {{ $t("editEvents.feesAreDeducted") }} </strong>
                  {{
                    $t(
                      "editEvents.beforeTheTotalAmmountIsTransferredToYourBankAccount"
                    )
                  }}
                  <strong
                    >7-30 {{ $t("editEvents.days") }}
                    {{
                      $t("editEvents.basedOnYourSubscriptionPlanRollingBasis")
                    }}
                  </strong>
                </small>
              </el-timeline-item>
            </el-timeline>
          </base-alert>
        </div>
      </card>
    </div>

    <div
      class="col-md-6"
      v-for="(ticket, index) in selected_tickets"
      :key="index"
    >
      <card class="border-bottom">
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb--1">
              <i class="fas fa-ticket-alt mr-2" />
              {{ ticket.name }}
            </p>
          </div>
        </div>

        <div class="card-body">
          <div class="row">

            <div class="col-md-12">
              <base-premium-overlay :enabled="isFreeUser() || isFreemiumUser()">
                <base-input
                  :label="$t('editEvents.price')"
                  placeholder="The ticket price will get an additional 3.99% + 0.99c as service fee"
                  v-model="ticket.price"
                  type="number"
                  :name="'Price (' + (index + 1) + ')'"
                  min="0"
                  step="0.5"
                  :successMessage="
                    ticket.price > 0
                      ? calcTicketingFinalPercentage()
                      : ''
                  "
                  :required="activeName === 'ticketing'"
                  :disabled="isFreeUser() || isFreemiumUser()"
                />
              </base-premium-overlay>
            </div>

            <div class="col" v-if="ticket.price > 0">
              <base-input :label="$t('editEvents.absorbServiceFee')">
                <base-switch
                  class="mr-1"
                  v-model="ticket.absorb_service_fee"
                  :infobox="{
                    label: $t('editEvents.theFinalTicketPriceIs'),
                    content: getTicketFinalPrice(ticket),
                  }"
                ></base-switch>
              </base-input>
            </div>

            <div class="col-md-12">
              <base-input
                :label="$t('editEvents.totalQuantity')"
                :placeholder="
                  $t('editEvents.totalNumberTicketsAvailableForSale')
                "
                type="number"
                :name="'Total Quantity (' + (index + 1) + ')'"
                v-model="ticket.total_quantity"
                :min="
                  item.event_type === 'physical' || item.event_type === 'hybrid'
                    ? 1
                    : undefined
                "
                :max="item.total_capacity"
                required
              />
            </div>

            <div class="col-md-12">
              <base-input :label="$t('editEvents.onSalesDates')">
                <flat-picker
                  slot-scope="{ blur }"
                  @on-open="customFocus()"
                  @on-close="blur"
                  :config="{
                    allowInput: true,
                    enableTime: false,
                    minDate: ticket.start_sale_at,
                    mode: 'range',
                    defaultDate: ticket.defaultDate,
                  }"
                  class="form-control datepicker"
                  v-model="ticket.sale_range"
                  :placeholder="
                    $t(
                      'editEvents.selectFromToDatesToMakeThisTicketAvailableForSales'
                    )
                  "
                  :name="'On-sale dates (' + (index + 1) + ')'"
                  required
                >
                </flat-picker>
              </base-input>
              <validation-error :errors="apiValidationErrors.sale_range" />
            </div>
          </div>
          <el-collapse accordion>
            <el-collapse-item :name="ticket.id + 'settings'">
              <template slot="title">
                <div class="d-flex w-100 justify-content-between">
                  <div>
                    <div class="d-flex w-100 align-items-center">
                      <h4 class="mb-1" style="margin-top: 0.75rem">
                        {{ $t("editEvents.settingAndCustomization") }}
                      </h4>
                    </div>
                  </div>
                  <small class="text-black-60">
                    {{ $t("editEvents.expand") }}
                  </small>
                </div>
              </template>

              <div class="col-md-12">
                <base-premium-overlay
                  :enabled="isFreeUser() || isFreemiumUser()"
                >
                  <base-input label="Collect Phone Number">
                    <base-switch
                      class="mr-1"
                      v-model="ticket.phone_number_enabled"
                      :description="{
                        content:
                          'A phone number is required from Attendees to complete the ticket booking',
                      }"
                    ></base-switch>
                  </base-input>
                </base-premium-overlay>
              </div>

              <div class="col-md-12">
                <base-premium-overlay
                  :enabled="isFreeUser() || isFreemiumUser()"
                >
                  <base-input :label="$t('editEvents.personalizedTickets')">
                    <base-switch
                      class="mr-1"
                      v-model="ticket.personalized_ticket"
                      :description="{
                        content: $t(
                          'editEvents.attendeesCanOnlyBookTicketsWithPersonalInfo'
                        ),
                      }"
                    ></base-switch>
                  </base-input>
                </base-premium-overlay>
              </div>

              <div class="col-md-12" v-if="!ticket.personalized_ticket">
                <base-input
                  :label="$t('editEvents.maximumQuantityPerBooking')"
                  :placeholder="
                    $t(
                      'editEvents.numberOfTicketsThatCanBePurchasedPerReservation'
                    )
                  "
                  v-model="ticket.max_quantity_per_person"
                  type="number"
                  name="Max quantity per person"
                  min="1"
                  :max="item.total_capacity"
                />
              </div>

              <div class="col-md-12">
                <base-input :label="$t('editEvents.customDescription')">
                  <textarea
                    v-model="ticket.custom_description"
                    name="description"
                    :placeholder="
                      $t(
                        'editEvents.overwriteTheGenericTicketDescriptionForThisEventOnly'
                      )
                    "
                    class="form-control"
                    rows="7"
                    max-length="340"
                  />
                </base-input>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="ticket.id + 'coupons'">
              <template slot="title">
                <div class="d-flex w-100 justify-content-between">
                  <div>
                    <div class="d-flex w-100 align-items-center">
                      <h4 class="mb-1" style="margin-top: 0.75rem">
                        Coupons & Discounts
                      </h4>
                    </div>
                  </div>
                  <small class="text-black-60"> Expand </small>
                </div>
              </template>
              <div class="col-md-12">
                <base-premium-overlay
                  :enabled="true"
                  badge-label="Coming Soon"
                  badge-style="warning"
                  badge-message="This new feature is under development"
                >
                  <base-input label="Create a new coupon">
                    <base-switch
                      class="mr-1"
                      v-model="ticket.personalized_ticket"
                      :description="{
                        content:
                          'Coupons are digital vouchers that can be used to redeem a specific discount for this ticket.',
                      }"
                    ></base-switch>
                  </base-input>

                  <base-input label="Create a new discount">
                    <base-switch
                      class="mr-1"
                      v-model="ticket.personalized_ticket"
                      :description="{
                        content:
                          'Discounts are price reductions for this ticket that are applied directly to the final price',
                      }"
                    ></base-switch>
                  </base-input>
                </base-premium-overlay>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </card>
    </div>
    <!-- Add Ticket - Modal -->
    <modal
      :show.sync="showAddTicketModal"
      modal-classes="modal-secondary"
      header-classes="calendar-modal-header"
    >
      <template slot="footer">
        <base-button type="secondary" @click="showAddTicketModal = false">{{
          $t("editEvents.close")
        }}</base-button>
      </template>

      <div class="calendar-modal-section">
        <add-ticket
          @ticketAdded="ticketAddedSuccessfully($event)"
          v-if="showAddTicketModal"
        ></add-ticket>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  Select,
  Option,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";
import Modal from "@/components/Modal.vue";
import AddTicket from "@/components/AddTicket/AddTicket.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import flatPicker from "vue-flatpickr-component";
import moment from "moment";
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";

// import { Slider } from "vue-color";
export default {
  name: "edit-event-tickets",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ["flatPicker"]: flatPicker,
    ValidationError,
    Modal,
    BaseInput,
    BaseButton,
    AddTicket,
    BasePremiumOverlay,
  },
  mixins: [formMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    activeName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      updatedItem: { id: null },
      unlimitedAttendees: false,
      tempSelectedTickets: null,
      itemToUpdate: null,
      modals: {
        openBooking: false,
        openVideo: false,
      },
      hover: false,
      itemId: 0,
      all_categories: [],
      all_tickets: [],
      selected_tickets: [],
      tags: [],
      tickets: [],
      allTimezones: [],
      status: "",
      showAddTicketModal: false,
      cloneState: false,
      color: {
        base: "#5e72e4ff",
      },
    };
  },
  created() {
    this.getTickets();
    this.allTimezones = moment.tz.names();
    this.cloneState = this.$route.query.clone === "true";
    // prevents attachments for Trix Editor:
    document.addEventListener("trix-file-accept", function (event) {
      event.preventDefault();
    });
  },
  mounted() {
    this.item?.event_tickets.forEach((ticket) => {
      // 1 - Prepare IDs for dropdown
      this.tickets.push(ticket.id.toString());

      // 2 - Load selected ticket-configs
      this.selected_tickets.push({
        price: ticket?.metadata.price,
        id: ticket.id.toString(),
        name: ticket.name.toString(),
        custom_description: ticket?.metadata.custom_description,
        absorb_service_fee: ticket?.metadata.absorb_service_fee === 1,
        max_quantity_per_person: ticket?.metadata.max_quantity_per_person,
        personalized_ticket: ticket?.metadata.personalized_ticket === 1,
        phone_number_enabled: ticket?.metadata?.phone_number_enabled,
        total_quantity:
          ticket?.metadata.total_quantity > -1
            ? ticket?.metadata.total_quantity
            : undefined,
        defaultDate: [
          ticket?.metadata.start_sale_at,
          ticket?.metadata.end_sale_at,
        ],
        sale_range:
          ticket?.metadata.start_sale_at +
          " to " +
          ticket?.metadata.end_sale_at,
      });
    });

    // 3 - Update child component for the changes
    this.$emit("ticketsChanged", this.selected_tickets);
  },
  computed: {
    publicURL() {
      return process.env.VUE_APP_BASE_URL + "/event/" + this.item.id;
    },
  },
  methods: {
    async getTickets() {
      let params = {
        filter: {
          ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
        },
      };
      try {
        await this.$store
          .dispatch("tickets/list", params)
          .then(
            () => (this.all_tickets = this.$store.getters["tickets/dropdown"])
          );
        if (this.isFreeUser() || this.isFreemiumUser()) {
          this.all_tickets.forEach((ticket) => {
            ticket.price = 0;
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("general.oopsSomethingWentWrong") + "!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    setSelectedTickets() {
      const eventTickets = this.tickets;
      this.selected_tickets = [];
      eventTickets.forEach((tempTicket) => {
        //check if the selected ticket already belongs to the event and set the relevant values
        if (this.item?.tickets.map((i) => i.id).indexOf(tempTicket) > -1) {
          this.item?.event_tickets.forEach((ticket) => {
            if (ticket.id === tempTicket) {
              this.selected_tickets.push({
                id: ticket.id,
                price: ticket.metadata.price,
                name: ticket.name,
                absorb_service_fee: ticket?.metadata.absorb_service_fee === 1,
                max_quantity_per_person:
                  ticket.metadata.max_quantity_per_person,
                custom_description: ticket?.metadata.custom_description,
                total_quantity:
                  ticket.metadata.total_quantity > -1
                    ? ticket.metadata.total_quantity
                    : undefined,

                sale_range:
                  ticket.metadata.start_sale_at +
                  " to " +
                  ticket.metadata.end_sale_at,
                defaultDate: [
                  ticket.metadata.start_sale_at,
                  ticket.metadata.end_sale_at,
                ],
                personalized_ticket: ticket.metadata.personalized_ticket === 1,
              });
            }
          });
        } else {
          this.all_tickets.forEach((element) => {
            if (element.id === tempTicket) {
              this.selected_tickets.push(element);
            }
          });
        }
      });
      this.$emit("ticketsChanged", this.selected_tickets);
    },
    ticketAddedSuccessfully(newTicket) {
      this.all_tickets.push({
        description: newTicket.description,
        name: newTicket.name,
        id: newTicket.id,
        price: this.isFreeUser() || this.isFreemiumUser() ? 0 : undefined,
      });
      this.showAddTicketModal = false;
    },
    getTicketFinalPrice(ticket) {
      const ticketPrice = Number(ticket.price);
      if (!isNaN(ticketPrice)) {
        if (ticket.absorb_service_fee || ticketPrice === 0) {
          ticket.final_price = ticketPrice;
        } else {

          // Ticketing Fees
          let finalPercentageFee  = 0;
          let finalFixedFee       = 0;
          if (this.isPremiumUser() ) {
            finalPercentageFee  = 0.0349;   // 3.49 %
            finalFixedFee       = 0.89;     // 0.89 cent
          } else if (this.isProfessionalUser() ) {
           finalPercentageFee  = 0.0299;   // 2.99 %
           finalFixedFee       = 0.69;     // 0.69 cent
          } else if (this.isCustomUser() ) {
            finalPercentageFee  = 0.0199;   // 1.99 %
            finalFixedFee       = 0.39;     // 0.39 cent
          } else {
            // Default fees (fallback)
            finalPercentageFee  = 0.0349;   // 3.49 %
            finalFixedFee       = 0.99;     // 0.99 cent
          }

          const fixedFinalPrice = ticketPrice + (ticketPrice * finalPercentageFee + finalFixedFee);
          ticket.final_price = fixedFinalPrice.toFixed(
            this.afterDecimal(ticketPrice)
          );
        }
      } else {
        ticket.final_price = 0;
      }
      return ticket.final_price;
    },
    calcTicketingFinalPercentage() {
      // Ticketing Fees
      let finalPercentageFee  = 0;
      let finalFixedFee       = 0;
      if (this.isPremiumUser() ) {
        finalPercentageFee  = 3.49;   // 3.49 %
        finalFixedFee       = 0.89;     // 0.89 cent
      } else if (this.isProfessionalUser() ) {
        finalPercentageFee  = 2.99;   // 2.99 %
        finalFixedFee       = 0.59;     // 0.59 cent
      } else if (this.isCustomUser() ) {
        finalPercentageFee  = 1.49;   // 1.49 %
        finalFixedFee       = 0.29;     // 0.29 cent
      } else {
        // Default fees (fallback)
        finalPercentageFee  = 3.99;   // 3.99 %
        finalFixedFee       = 0.99;     // 0.99 cent
      }
      return 'The Ticket Price will get an additional '
          + finalPercentageFee + '%'
          + ' + ' + finalFixedFee + 'c'
           + ' as service fee.'
          + ' If you wish to lower your fees please upgrade your Subscription Plan and save more on every ticket sold' ;
    },
    afterDecimal(num) {
      if (Number.isInteger(num)) {
        return 2;
      } else if (num)
        return num.toString().split(".")[1].length > 2
          ? num.toString().split(".")[1].length
          : 2;
    },
    customFocus() {
      // ToDo - Mone this method as global to fix the datepicker for the whole app
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar open")[0].focus();
      }, 100);
    },
  },
};
</script>
